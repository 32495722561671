<template>
    <div class="innovation-talent">
        <AonCoverLoading
            :loading="loadingData"
            title="Loading Innovation Over Time..."
        ></AonCoverLoading>
        <AonCoverLoading
            v-if="noData"
            :loading="noData ? true : false"
            :show-spinner="false"
            title="No Innovation Talent Identified"
        >
            <template #header>
                <div class="icon">
                    <font-awesome-icon
                        icon="fas fa-circle-exclamation"
                        class="grey01--text"
                        size="2xl"
                    />
                </div>
            </template>
        </AonCoverLoading>
        <MoatTable
            v-if="axisRange.length > 0 && !loadingData"
            class="w-full h-full"
            :key="`tech_areas_${tableUpdater}`"
            :class="themeClass"
            :column-defs="colDefs"
            :tooltip-interaction="true"
            :tooltip-mouse-track="true"
            :tooltip-show-delay="500"
            tooltip-show-mode="whenTruncated"
            :autoGroupColumnDef="autoGroupColumnDef"
            :groupRemoveLowestSingleChildren="true"
            :groupDisplayType="groupDisplayType"
            :treeData="true"
            :getDataPath="getDataPath"
            :row-data="finalTableData"
            @grid-ready="gridInit"
        />
        <!-- @first-data-rendered="getMaxAndFilterData" -->
    </div>
</template>

<script setup>
import { onBeforeMount, ref, inject } from 'vue'
import { useMoat2ProductStore } from '@/stores'

import { getInnovationTalent } from '@/api/productAlignment.js'
import { useI18n } from 'vue-i18n'

// Moat Table specific
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'

// Injectors
const filters = inject('filters')
const logger = inject('logger')
const { t } = useI18n()

// Store
const moat2ProductStore = useMoat2ProductStore()

const columnFormatter = (params) => {
    const { yValue } = params

    return {
        // if yValue is negative, the marker should be 'red', otherwise it should be 'green'
        fill: yValue < 0 ? '#E06259' : '#2A79D2',
        stroke: yValue < 0 ? '#E06259' : '#2A79D2',
    }
}

// Vars
const loadingData = ref(true)
const noData = ref(false)
const gridApi = ref(null)
const tableUpdater = ref(0)
const finalTableData = ref([])
const getDataPath = ref(null)
const axisRange = ref([0, 10])
const colDefs = ref([
    {
        field: 'experienceByYear',
        headerTooltip: 'Experience by Year',
        flex: 2,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
            sparklineOptions: {
                type: 'column',
                xKey: 'year',
                yKey: 'netInventorExperience',
                valueAxisDomain: axisRange.value,
                axis: { type: 'number' },
                tooltip: {
                    renderer: (params) => {
                        const { x, y } = params.datum
                        return {
                            title: `${x}:`,
                            content: y,
                        }
                    },
                },
                formatter: columnFormatter,
            },
        },
    },
])
const autoGroupColumnDef = ref(null)
const groupDisplayType = ref(null)
const themeClass = ref('ag-theme-quartz')

onBeforeMount(() => {
    defineTableGroups()
    getDataPath.value = (data) => {
        return data.nodeHierarchy.map((item) => item)
    }
    groupDisplayType.value = 'singleColumn'
    getDataGroomTree()
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()

    gridApi.value.setGridOption('columnDefs', colDefs.value)
    gridApi.value.refreshCells({ force: true })
}

const getDataGroomTree = async (params) => {
    try {
        let { data } = await getInnovationTalent(moat2ProductStore.alignmentPk)
        finalTableData.value = data
    } catch (error) {
        logger.error(error)
    } finally {
        loadingData.value = false
    }

    if (finalTableData.value.length === 0) {
        noData.value = true
        return
    }

    // Max value for sparkline
    const max = Math.max(...finalTableData.value.map((item) => item.experienceByYear))
    axisRange.value = [0, max]
    tableUpdater.value++
}

const defineTableGroups = () => {
    autoGroupColumnDef.value = {
        minWidth: 450,
        flex: 2,
        field: 'displayName',
        tooltipField: 'displayName',
        suppressMovable: true,
        lockPosition: 'left',
        headerName: 'Product',
        cellRendererParams: {
            suppressCount: true,
        },
    }
}
</script>

<style lang="scss" scoped>
.innovation-talent {
    width: 100%;
    position: relative;
    height: 400px;
}
</style>
