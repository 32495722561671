<template>
    <div class="entity-selection-table d-flex flex-column">
        <div class="header-wrap d-flex justify-content-between">
            <div class="text-wrap">
                <h6 v-if="props.tableHeading">{{ props.tableHeading }}</h6>
                <p class="grey02--text">{{ props.tableSubHeading }}</p>
            </div>
            <AonInput
                class="mb-5"
                style="width: 400px"
                v-model="searchTerm"
                search-input
                placeholder="Search for company"
                focus
                @keyup="searchTable()"
                @clear="clearSearch()"
            />
        </div>
        <MoatTable
            class="w-full flex-grow-1"
            :defaultColDef="defaultColDef"
            :class="themeClass"
            :column-defs="colDefs"
            :tooltip-interaction="true"
            :tooltip-mouse-track="true"
            :tooltip-show-delay="500"
            tooltip-show-mode="whenTruncated"
            :autoSizeStrategy="sizingStrategy"
            row-model-type="serverSide"
            :cache-block-size="50"
            :max-blocks-in-cache="10"
            :get-row-id="getRowId"
            :sideBar="sideBar"
            :row-selection="rowSelection"
            :noRowsOverlayComponent="MTCustomNoRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            @grid-ready="gridInit"
            @first-data-rendered="onFirstDataRendered"
            @row-selected="selectEntity"
        />
    </div>
</template>

<script setup>
import { inject, ref, computed, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'

import { getEntitySearchQuery } from '@/lib/openSearchQueryBuilder'
import * as osApi from '@/api/opensearch'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { moatParamBuilder } from '@/components/moatTable/helpers/queryBuilder.js'
import MTCustomNoRowsOverlay from '@/components/moatTable/MTCustomNoRowsOverlay.vue'

const { t } = useI18n()
const logger = inject('logger')
const filters = inject('filters')

const emit = defineEmits(['selected-entities'])
const props = defineProps({
    maxSelections: {
        type: Number,
        default: 1,
    },
    tableHeading: {
        type: String,
        default: '',
    },
    tableSubHeading: {
        type: String,
        default: '',
    },
    initEmptyTable: {
        type: Boolean,
        default: false,
    },
    initEmptyTableMessage: {
        type: String,
        default: '',
    },
    initSelectedEntity: {
        type: String,
        default: null,
    },
})

// Table
const noRowsOverlayComponentParams = ref({
    message: props.initEmptyTableMessage,
})
const userHasNotStarted = ref(true)
const searchTerm = ref('')
const gridApi = ref(null)
const sideBar = ref(null)
const selectedRows = ref([])
const tablePageSize = ref(50)
const tablePageNum = ref(0)
const defaultColDef = ref({
    sortable: false,
})
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 2600,
})
const rowSelection = ref({
    mode: 'single',
    headerCheckbox: false,
    isRowSelectable: (node) => {
        return node.data?.selectable ?? true
    },
})
const colDefs = ref([
    {
        field: 'name',
        headerName: 'Company',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
        headerTooltip: 'Company',
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        width: 400,
        suppressHeaderMenuButton: true,
    },
    {
        field: 'overall_score',
        headerName: 'Moat Quality',
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.overall_score ? `${Math.round(p.data.overall_score)}` : '--'
            }
        },
        suppressHeaderMenuButton: true,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.coverage'),
            headerName: 'Scope',
            ignoreSort: true,
        },
        headerTooltip: 'Scope',
        field: 'coverage_score',
        minWidth: 100,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.coverage_score ? Math.round(p.data.coverage_score).toFixed(0) : '--'
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.opportunity'),
            headerName: 'Momentum',
            ignoreSort: true,
        },
        headerTooltip: 'Momentum',
        field: 'opportunity_score',
        minWidth: 150,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.opportunity_score ? `${Math.round(p.data.opportunity_score)}` : '--'
            }
        },
        chartDataType: 'series',
        // filter: 'agNumberColumnFilter',
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('definitionTooltips.risk.definition'),
            headerName: 'Resilience',
            ignoreSort: true,
        },
        headerTooltip: 'Resilience',
        field: 'risk_score',
        minWidth: 150,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.risk_score ? `${Math.round(p.data.risk_score)}` : '--'
            }
        },
    },
    {
        field: 'total_revenue',
        headerName: 'Annual Revenue',
        width: 250,
        suppressHeaderMenuButton: true,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.total_revenue, 2)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.innImpliedPE'),
            headerName: 'II P/E',
            ignoreSort: true,
        },
        headerTooltip: 'Innovation Implied P/E',
        field: 'target_price_to_earnings',
        width: 150,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.target_price_to_earnings
                    ? `${parseInt(p.data.target_price_to_earnings).toFixed(2)}`
                    : '--'
            }
        },
    },
    {
        field: 'price_to_earnings',
        headerName: 'P/E',
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.price_to_earnings
                    ? `${parseInt(p.data.price_to_earnings).toFixed(2)}`
                    : '--'
            }
        },
    },
    {
        field: 'price_to_earnings_delta',
        headerName: 'Innovation Implied P/E Delta',
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.price_to_earnings_delta
                    ? `${parseInt(p.data.price_to_earnings_delta).toFixed(2)}`
                    : '--'
            }
        },
    },
    {
        field: 'hq_country_name',
        headerName: 'HQ Country',
        suppressHeaderMenuButton: true,
    },
    {
        field: 'ultimate_aon_entity_name',
        headerName: 'Ultimate Parent',
        suppressHeaderMenuButton: true,
    },
    {
        field: 'employee_count',
        headerName: 'Employee Count',
        suppressHeaderMenuButton: true,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.employee_count ? p.data.employee_count.toLocaleString() : '--'
            }
        },
    },
    {
        field: 'patent_count_us',
        headerName: 'Active Assets',
        suppressHeaderMenuButton: true,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.patent_count_us ? p.data.patent_count_us.toLocaleString() : '--'
            }
        },
    },
    {
        field: 'ownership_status',
        headerName: 'Ownership Status',
        suppressHeaderMenuButton: true,
    },
    {
        field: 'homepage_url',
        headerName: 'URL',
        suppressHeaderMenuButton: true,
    },
])

const isEmptyTable = computed(() => {
    return props.initEmptyTable && userHasNotStarted.value
})

const gridInit = (params) => {
    gridApi.value = params.api
    setupServerSideData()

    if (props.initSelectedEntity) {
        searchTerm.value = props.initSelectedEntity
        gridApi.value.setColumnFilterModel('name', {
            filterType: 'text',
            type: 'equals',
            filter: props.initSelectedEntity,
        })

        gridApi.value.onFilterChanged()

        gridApi.value.addEventListener(
            'modelUpdated',
            () => {
                gridApi.value.forEachNode((node) => {
                    if (node.data.name === props.initSelectedEntity) {
                        node.setSelected(true)
                    }
                })
            },
            { once: true }
        )
    }

    // Add filter change listener to clear selections
    gridApi.value.addEventListener('filterChanged', () => {
        gridApi.value.deselectAll()
        selectedRows.value = []
        emit('selected-entities', [])
    })

    sideBar.value = {
        toolPanels: [
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    }
}

const onFirstDataRendered = (params) => {
    const filterInstance = params.api.getToolPanelInstance('filters')
    filterInstance.expandFilters()
}

const getRowId = (params) => {
    return params.data.aon_entity_pk
}

const setupServerSideData = async () => {
    if (isEmptyTable.value) {
        gridApi.value.showNoRowsOverlay()
        return
    }

    const datasource = createServerSideDatasource()
    gridApi.value.setGridOption('serverSideDatasource', datasource)
}

const fetchCompetitorData = async (paramsFromTable) => {
    try {
        let finalParams = moatParamBuilder(
            paramsFromTable,
            { page_size: tablePageSize.value, last_row_num: 0, page: tablePageNum.value },
            null
        )
        const osParams = getEntitySearchQuery(finalParams)
        const { data } = await osApi.searchEntities(osParams)
        const scrubbedData = data.hits.hits.map(({ _source }) => ({ ..._source }))

        tablePageNum.value++
        return {
            success: true,
            rows: scrubbedData,
        }
    } catch (err) {
        logger.error(err)
        return {
            success: false,
        }
    }
}

const createServerSideDatasource = () => {
    return {
        getRows: async (params) => {
            const response = await fetchCompetitorData(params.request)

            if (response.success) {
                params.success({ rowData: response.rows })
            } else {
                params.fail()
            }
        },
    }
}

const selectEntity = (event) => {
    selectedRows.value = gridApi.value.getSelectedRows()

    emit('selected-entities', selectedRows.value)
}

const searchTable = async () => {
    userHasNotStarted.value = false

    if (!searchTerm.value) {
        gridApi.value.showNoRowsOverlay()
        gridApi.value.setFilterModel(null)
        userHasNotStarted.value = true
        return
    } else {
        gridApi.value.hideOverlay()
    }

    await gridApi.value.setColumnFilterModel('name', {
        filterType: 'text',
        type: 'startsWith',
        filter: searchTerm.value,
    })

    // Repopulate the table if it was initially empty
    if (!isEmptyTable.value) {
        const datasource = createServerSideDatasource()
        gridApi.value.setGridOption('serverSideDatasource', datasource)
    }

    gridApi.value.onFilterChanged()
}

const clearSearch = () => {
    searchTerm.value = ''
    gridApi.value.showNoRowsOverlay()
    gridApi.value.setFilterModel(null)
    userHasNotStarted.value = true
}
</script>

<style lang="scss" scoped>
.entity-selection-table {
    width: 100%;
    height: 100%;

    :deep(.disabled-checkbox) {
        .ag-selection-checkbox {
            opacity: 0.2;
            pointer-events: none;
        }
    }
}
</style>
