<template>
    <div class="popper-market-metric pa-4">
        <h6>
            {{ title }}
        </h6>
        <p class="mt-2">
            {{ definition }}
        </p>
        <div class="mt-4">
            <a class="link" @click="setGlobalModal">
                {{ $t('definitionTooltips.more') }}
            </a>
            <font-awesome-icon
                icon="fas fa-external-link"
                class="ml-2 knights-cloak--text"
                size="sm"
            />
        </div>
    </div>
</template>

<script setup>
import { useAppStore } from '@/stores'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    definition: {
        type: String,
        required: true,
    },
    moreInfo: {
        type: String,
        required: true,
    },
    zScoreInfo: {
        type: String,
        required: false,
    },
})

const setGlobalModal = () => {
    const appStore = useAppStore()
    const modalObj = {
        type: 'metric',
        caption: '',
        title: props.title,
        definition: props.definition,
        moreInfo: props.moreInfo,
        zScoreInfo: props.zScoreInfo,
    }
    appStore.setGlobalModal(modalObj)
    appStore.setShowGlobalModal(true)
}
</script>

<script>
export default {
    name: 'PopperMarketMetric',
}
</script>

<style lang="scss" scoped>
.popper-market-metric {
    max-width: 400px;
}
</style>
