<template>
    <div class="no-rows-overlay d-flex align-items-center justify-content-center">
        <h6>{{ params.message }}</h6>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, inject } from 'vue'

const eventBus = inject('eventBus')
const emit = defineEmits(['setup-arena'])
const props = defineProps({
    params: Object,
})
</script>

<style lang="scss">
.no-rows-overlay {
    width: 100%;
    height: 100%;
    background: white;
}
</style>
