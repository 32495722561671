<template>
    <div class="contender-selection-track">
        <SelectContenderSource v-if="!reportGenerationStore.shouldShowContenderSelection" />
        <CompetitorSelectionSplit
            v-if="allCompetitors.length > 0 && reportGenerationStore.shouldShowContenderSelection"
            class="flex-grow-1"
            :all-competitor-data="allCompetitors"
            :selected-table-data="selectedCompetitors"
            :max-selected-competitors="8"
            :filter-name-list="[reportGenerationStore.targetCompany]"
            @add-selection="addSelection"
            @delete-selection="updateSelections"
        />
        <AonCoverLoading
            :loading="reportGenerationStore.shouldUpdateContenderList"
            title="Populating Potential Contenders"
        />
    </div>
</template>

<script setup>
import { ref, inject, onMounted, nextTick, computed, onActivated } from 'vue'
import { useReportGenerationStore, useMoat2ProductStore, useArenaOutputStore } from '@/stores'
import { cloneDeep } from 'lodash-es'

import CompetitorSelectionSplit from '@/components/helpers/CompetitorSelectionSplit.vue'
import SelectContenderSource from './SelectContenderSource.vue'

const reportGenerationStore = useReportGenerationStore()
const emit = defineEmits(['update-tables'])

onMounted(async () => {
    await nextTick()
})

const allCompetitors = computed(() => {
    return reportGenerationStore.allCompetitorData
})

const selectedCompetitors = computed(() => {
    return reportGenerationStore.reportSelections[3]?.value
})

const addSelection = (competitor) => {
    const currentList = cloneDeep(reportGenerationStore.reportSelections[3].value)
    let newCompetitorList = [...currentList, competitor]
    reportGenerationStore.reportSelections[3].value = newCompetitorList
}

const updateSelections = (competitor) => {
    let newCompetitorList = reportGenerationStore.reportSelections[3].value.filter(
        (item) => item.aonEntityName !== competitor.aonEntityName
    )

    reportGenerationStore.reportSelections[3].value = newCompetitorList
}
</script>

<style lang="scss" scoped>
.contender-selection-track {
    height: 100%;
    width: 100%;

    position: relative;
}
</style>
