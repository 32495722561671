<template>
    <div class="playbook-creation-track h-full" :class="[{ hide: hideStepTracker }]">
        <div class="headings-holder">
            <h5 v-if="heading" class="heading white--text knights-cloak pa-5">
                {{ heading }}
            </h5>
            <p
                v-if="headingDesc"
                id="headingDesc"
                class="delay-hook heading-desc grey01--text mb-5"
            >
                {{ headingDesc }}
            </p>
        </div>
        <div class="track-holder knights-cloak-80 pa-5">
            <div class="init-step-container">
                <div
                    v-for="(step, index) in playbookGenerationSteps"
                    class="step pb-5"
                    :class="[{ current: step.isCurrentStep }, { complete: step.isCompletedStep }]"
                >
                    <div class="wrap-helper d-flex align-items-center">
                        <div class="path-holder">
                            <div class="path"></div>
                        </div>
                        <div class="step-num d-flex justify-content-center align-items-center">
                            <p v-if="!step.isCompletedStep" class="white--text">{{ index + 1 }}</p>
                            <font-awesome-icon
                                v-else
                                icon="fas fa-check"
                                class="white--text"
                                size="lg"
                            />
                        </div>
                        <p
                            class="large bold step-label white--text ml-2"
                            :class="{ 'redacted-regular': step.redacted }"
                        >
                            {{ step.stepName }}
                        </p>
                        <font-awesome-icon
                            v-if="playbookGenerationStore.shouldUpdateContenderList && index === 3"
                            icon="fas fa-circle-exclamation"
                            class="castle-moat--text ml-3"
                            size="lg"
                            v-tooltip="{
                                content:
                                    'We\'ve noticed a change in the selected Technology Areas. Updating Contenders...',
                            }"
                        />
                    </div>
                    <p
                        class="white--text step-desc pl-11 mt-1"
                        :class="{ 'redacted-regular': step.redacted }"
                    >
                        {{ step.stepDesc }}
                    </p>
                    <div
                        v-if="
                            playbookGenerationStore.trackStepsFinal.length !== 0 &&
                            playbookGenerationStore.playbookSelections[index]?.value &&
                            playbookGenerationStore.trackStepsFinal[index].isCompletedStep
                        "
                        class="step-selection mt-4 ml-5 px-4 d-flex align-items-center justify-content-center"
                        :class="{
                            'allow-edit':
                                playbookGenerationStore.playbookSelections[index].allowEdit,
                        }"
                        @click="
                            gotoStep(
                                playbookGenerationStore.playbookSelections[index].allowEdit,
                                step.stepNum
                            )
                        "
                    >
                        <p>
                            {{
                                Array.isArray(
                                    playbookGenerationStore.playbookSelections[index].value
                                )
                                    ? `${playbookGenerationStore.playbookSelections[index].value.length}  ${playbookGenerationStore.playbookSelections[index].selectionLabel}`
                                    : playbookGenerationStore.playbookSelections[index]
                                          .selectionLabel
                            }}
                        </p>
                        <font-awesome-icon
                            v-if="!playbookGenerationStore.playbookSelections[index].allowEdit"
                            icon="fas fa-lock"
                            class="grey01--text ml-3"
                            size="lg"
                        />
                        <font-awesome-icon
                            v-else
                            icon="fas fa-gear"
                            class="castle-moat--text ml-3"
                            size="lg"
                        />
                    </div>
                </div>
                <div
                    v-if="playbookGenerationStore.trackStepsFinal.length === 0"
                    class="cover-up"
                ></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'

const playbookGenerationStore = usePlaybookGenerationStore()
const emit = defineEmits(['step-clicked'])

const props = defineProps({
    passedSteps: {
        type: Object,
        default: () => {},
    },
    currentStep: {
        type: Number,
        default: null,
    },
    heading: {
        type: String,
        default: '',
    },
    headingDesc: {
        type: String,
        default: '',
    },
    hideOnStep: {
        type: Number,
        default: null,
    },
    allowStepNavigation: {
        type: Boolean,
        default: false,
    },
})

const collapse = ref(false)
const headingsHolderHeight = ref()

onMounted(() => {})

const playbookGenerationSteps = computed(() => {
    return playbookGenerationStore.trackStepsFinal.length > 0
        ? playbookGenerationStore.trackStepsFinal
        : playbookGenerationStore.trackStepsInit
})

const gotoStep = (allowEdit, stepNum) => {
    if (!allowEdit) {
        return
    }
    playbookGenerationStore.setCurrentStep(stepNum)
}

const hideStepTracker = computed(() => {
    return props.hideOnStep && props.currentStep ? props.currentStep === props.hideOnStep : false
})
</script>

<style lang="scss" scoped>
.playbook-creation-track {
    width: 100%;
    min-width: 400px;
    max-width: 400px;
    height: 100%;
    border-right: 1px solid $grey05;
    background: $knightsCloak80;
    overflow: auto;

    position: relative;

    transition: all 0.3s ease-in-out;

    .init-step-container {
        position: relative;
        .cover-up {
            height: 100%;
            width: 100%;
            background: linear-gradient(0deg, rgba(38, 59, 82, 1) 15%, rgba(38, 59, 82, 0) 100%);

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    .step {
        position: relative;

        // With Current Class applied
        &.current {
            .step-num {
                border-color: $gold;

                p {
                    color: $gold;
                }
            }

            .step-label {
                color: $gold;
            }

            .complete-path {
                top: calc(50% - 9px);
            }

            .path-holder {
                .path {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        &.complete {
            .path-holder {
                .path {
                    border-style: solid;
                }
            }
        }

        &:last-of-type {
            .path-holder {
                .path {
                    pointer-events: none;
                    opacity: 0;
                }
            }
        }

        // Base State
        .step-num {
            width: 36px;
            height: 36px;
            border: solid 2px white;
            border-radius: 50%;
            background: $knightsCloak80;

            position: relative;
            z-index: 1;
        }

        .path-holder {
            height: calc(100% - 36px);

            position: absolute;
            top: 36px;
            left: 17px;
            z-index: 0;

            .path {
                height: calc(100%);
                border-right: dashed 2px white;

                position: relative;

                &:before {
                    content: '';
                    display: block;
                    width: 2px;
                    height: 50%;
                    background: $gold;

                    position: absolute;
                    top: 0;
                    left: 0;

                    opacity: 0;
                }
            }
        }

        .step-selection {
            width: fit-content;
            max-width: 350px;
            height: 50px;
            background: white;
            border-radius: 0 25px 25px 0;
            outline: 1px solid $castleMoat;
            border: 2px solid $castleMoat;

            &.allow-edit {
                cursor: pointer;
                p {
                    color: $castleMoat;
                    text-decoration: underline;
                }
            }
        }
    }

    &.hide {
        display: none;
    }

    .handle {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        cursor: pointer;

        position: absolute;
        top: 50%;
        right: -20px;
        background: white;
        transform: translateY(-50%);

        transition: all 0.3s ease-in-out;

        .icon {
            transform: rotate(0);

            transition: all 0.3s ease-in-out;
        }
    }
}
</style>
