<template>
    <div class="company-header" :class="collapsed ? 'collapsed' : ''">
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" color="castleMoat" />
        <AonContainer v-else>
            <AonRow>
                <AonCol class="aon-col-12 pa-0 justify-content-between d-flex align-items-center">
                    <div class="expand-entity-holder d-flex flex-wrap">
                        <EntityTitle v-if="entity" :entity="entity" dark is-research />
                    </div>
                    <div class="button-holder d-flex">
                        <AonButton
                            class="mr-4"
                            :label="$t('entitySummary.showHierarchy')"
                            :text-with-icon="true"
                            type="transparent"
                            :icon-options="{ iconStyle: 'fas', iconName: 'fa-sitemap' }"
                            @clicked="showCompanyHierarchy = true"
                        />
                        <AddToWatchlistButton
                            v-if="entity"
                            :key="parseInt(entity.aon_entity_pk)"
                            :id-values="[entity.aon_entity_pk]"
                            id-type="Entity"
                            type="transparent"
                            button-width="auto"
                        />
                        <AonButton
                            class="ml-4"
                            label="Generate Report"
                            text-with-icon
                            :icon-options="{ iconStyle: 'fas', iconName: 'fa-download' }"
                            @clicked="gotoReportGenerator()"
                        />
                        <AonButton
                            v-if="showPatentData"
                            class="ml-4"
                            label="Export Product Patent Portfolio"
                            text-with-icon
                            :loading="exportLoading"
                            :disabled="!moat2ProductStore.productAlignmentAllData.length > 0"
                            :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                            @clicked="exportPatents"
                        />
                    </div>
                </AonCol>
            </AonRow>
            <CompanyHierarchy
                class="comapny-page-hierarchy"
                v-if="showCompanyHierarchy"
                :parent-entity-id="parentEntityId"
                :selected-entity-id="parseInt(entity.aon_entity_pk)"
                @cancel="showCompanyHierarchy = false"
            />
        </AonContainer>
    </div>
</template>

<script setup>
import { computed, ref, inject, onBeforeUnmount, onMounted } from 'vue'
import { useEntityStore, useMoat2ProductStore, useReportGenerationStore } from '@/stores'
import { pollForExport } from '@/api/productAlignment.js'

import { downloadFile } from '@/composables/presignedUrlDownloader.js'
import { useFlag } from '@unleash/proxy-client-vue'
import { useAuth } from '@/auth/authPlugin'
import { useRouter } from 'vue-router'

import EntityTitle from '@/components/company/EntityTitle'
import CompanyHierarchy from '@/components/company/CompanyHierarchy'
import AddToWatchlistButton from '../Watchlists/WatchlistActions/AddToWatchlistButton'

const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()
const reportGenerationStore = useReportGenerationStore()
const { user } = useAuth()
const logger = inject('logger')
const eventBus = inject('eventBus')

const showPatentData = useFlag('ipAlpha.patentData')
const router = useRouter()

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    entity: {
        type: Object,
        default: null,
    },
    collapsed: {
        type: Boolean,
        default: false,
    },
})
const showCompanyHierarchy = ref(false)
const peersLoading = ref(false)
const generateLoading = ref(false)
const exportLoading = ref(false)
let interval = ref(0)

onMounted(() => {
    eventBus.on('set-arena-space-loading', (bool) => {
        peersLoading.value = bool
    })
})

onBeforeUnmount(() => {
    clearInterval(interval)
})

const parentEntityId = computed(() => {
    return props.entity.ultimate_aon_entity_pk
        ? parseInt(props.entity.ultimate_aon_entity_pk)
        : parseInt(props.entity.aon_entity_pk)
})

const allowReportGen = computed(() => {
    return !moat2ProductStore.productAlignmentTopCompetitors?.length > 0 || peersLoading.value
})

const gotoReportGenerator = async () => {
    generateLoading.value = true

    reportGenerationStore.targetCompany = entityStore.entity.name
    router.push({ name: 'Create Report', query: { source: 'pa' } })
}

const exportPatents = async () => {
    exportLoading.value = true
    getPAExportStatus()
    interval = setInterval(async () => {
        getPAExportStatus()
    }, 5000)
}

const getPAExportStatus = async () => {
    await pollForExport(
        moat2ProductStore.alignmentPk,
        async (data) => {
            // success
            clearInterval(interval)
            eventBus.emit('snacktime', {
                type: 'success',
                message: 'Export Successful. Downloading...',
            })
            downloadFile(data.exportPresignedUrl)
            exportLoading.value = false
        },
        (err) => {
            // failure
            eventBus.emit('snacktime', {
                type: 'error',
                message: 'Export Failed. Please contact Luke Shoop for further assistance.',
            })
            clearInterval(interval)
        }
    )
}
</script>

<style lang="scss" scoped>
.company-header {
    width: 100%;
    position: relative;

    .description-wrap,
    .description,
    .meta-holder,
    .market-metrics {
        opacity: 1;
    }

    &.collapsed {
        height: 100px;

        .description-wrap,
        .meta-holder,
        .market-metrics {
            opacity: 0;
            height: 0;
            margin: 0 !important;
        }
    }

    .link:hover {
        color: $grey03;
    }

    .a-card {
        background: rgba($grey01, 0.4);
    }

    .toggle-meta {
        cursor: pointer;

        .drop-icon {
            height: 16px;

            transition: transform 0.3s ease-in-out;

            &.open {
                transform: rotate(90deg);
            }
        }
    }

    .market-metrics,
    .meta-holder {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 24px;

        .meta {
            min-width: 200px;
        }

        @media screen and (max-width: 1220px) {
            flex-wrap: wrap;

            .meta {
                width: 100%;
                margin: 4px 0;
            }
        }
        .address {
            word-break: break-word;
        }

        .metric-icon {
            cursor: initial;
            transition: all 0.3s ease-in-out;
            color: rgba($grey08, 0.6);

            p {
                color: white !important;
            }

            :hover {
                color: white;
            }
        }
    }
}
</style>
