<template>
    <div class="npe-campaigns-card grey04">
        <AonCard
            style="position: relative; min-height: 200px"
            :class="{ 'remove-padding': !props.hideHeader }"
            :title="props.title"
            :desc="props.description"
        >
            <div class="card-content">
                <AonCoverLoading
                    :loading="loadingNPECampaigns"
                    title="Loading NPE Campaigns..."
                ></AonCoverLoading>
                <div class="card-content-innner">
                    <div v-if="!props.hideHeader" class="heading pa-5 heading-background">
                        <p class="extra-small grey01--text bold uppercase mb-1">
                            Potential NPE CAMPAIGNS
                        </p>
                        <p class="large grey01--text" style="">Identify and Mitigate</p>
                        <h6 class="brown-highlight--text bold mb-1">NPE Risks</h6>
                        <p class="grey01--text">
                            Discover recent Non-Practicing Entity (NPE) suits within this area.
                        </p>
                    </div>
                    <div
                        class="case-accordion-holder pa-5"
                        :class="{ 'remove-padding': props.hideHeader }"
                        style="position: relative; min-height: 200px"
                    >
                        <div
                            v-for="npeItem in NPEData"
                            class="bellow case mb-3"
                            :class="[{ show: npeItem.showNPECases }]"
                        >
                            <div
                                class="bellow-cta pa-3 d-flex align-items-center justify-content-between"
                                @click="onShowNPECases(npeItem)"
                            >
                                <div class="npe-name d-flex align-items-center">
                                    <div class="npe-name-holder d-flex align-items-center">
                                        <font-awesome-icon
                                            :icon="`fas fa-caret-right`"
                                            class="mr-2 grey04--text bellow-caret"
                                        />
                                        <div
                                            class="initiators-holder d-flex align-items-center w-full"
                                        >
                                            <div
                                                v-for="(initiator, index) in npeItem.initiators"
                                                :key="initiator"
                                                class="initiator d-flex align-items-center justify-content-between"
                                            >
                                                <h6 class="castle-moat--text">
                                                    {{ initiator.entityName }}
                                                </h6>
                                                <h6
                                                    v-if="
                                                        initiator.absoluteParentEntityName &&
                                                        initiator.entityName !==
                                                            initiator.absoluteParentEntityName
                                                    "
                                                    class="castle-moat--text small bold ml-1 mt-1"
                                                >
                                                    ({{ initiator.absoluteParentEntityName }})
                                                </h6>
                                                <h6
                                                    v-if="index !== npeItem.initiators.length - 1"
                                                    class="castle-moat--text mx-2"
                                                >
                                                    :
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="npe-stat-holder d-flex align-content-center justify-start"
                                >
                                    <div v-if="npeItem.startedOn" class="stat-item mr-5">
                                        <p
                                            class="grey01--text extra-small bold uppercase"
                                            style="white-space: nowrap"
                                        >
                                            Date Campaign Started
                                        </p>
                                        <p class="grey01--text">
                                            {{ filters.toUTCString(npeItem.startedOn) }}
                                        </p>
                                    </div>
                                    <div v-if="npeItem.openCaseCount" class="stat-item mr-5">
                                        <p class="grey01--text extra-small bold uppercase">
                                            Cases Open
                                        </p>
                                        <p class="grey01--text">
                                            {{ npeItem.openCaseCount.toLocaleString() }}
                                        </p>
                                    </div>
                                    <div v-if="npeItem.closedCaseCount" class="stat-item mr-5">
                                        <p class="grey01--text extra-small bold uppercase">
                                            Cases Closed
                                        </p>
                                        <p class="grey01--text">
                                            {{ npeItem.closedCaseCount.toLocaleString() }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bellow-content">
                                <AonSpinner
                                    v-if="npeItem.loadingCases"
                                    class="ml-auto mr-auto my-5"
                                    :scale="0.3"
                                />
                                <div
                                    v-if="
                                        !npeItem.loadingCases &&
                                        npeItem.cases &&
                                        npeItem.cases.length > 0
                                    "
                                    class="case-holder pa-5 mt-n4"
                                    style="min-height: 150px"
                                >
                                    <CaseDetails
                                        v-for="caseItem in npeItem.cases"
                                        :caseData="caseItem"
                                        class="my-2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <AonCoverLoading
                        v-if="noData"
                        :loading="noData ? true : false"
                        :show-spinner="false"
                        title="No NPE Data Found"
                    >
                        <template #header>
                            <div class="icon">
                                <font-awesome-icon
                                    icon="fas fa-circle-exclamation"
                                    class="grey01--text"
                                    size="2xl"
                                />
                            </div>
                        </template>
                    </AonCoverLoading>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import CaseDetails from './shared/CaseDetails.vue'

import { getNPECampaigns, getCasesForNPE } from '@/api/ipNode'

const filters = inject('filters')

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
    hideHeader: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: '',
    },
    description: {
        type: String,
        default: '',
    },
})

const logger = inject('logger')

const loadingNPECampaigns = ref(true)
const NPEData = ref(null)
const noData = ref(false)

onMounted(() => {
    onGetNPECampigns()
})

const onGetNPECampigns = async () => {
    loadingNPECampaigns.value = true
    try {
        const params = {
            SC: ['startedOn'],
            SD: [0],
            FD: [],
            FT: [],
            FV: [],
            page_size: 10,
            page: 1,
        }
        const { data } = await getNPECampaigns(props.nodeData.id, params)

        if (data.results.length === 0) {
            noData.value = true
        } else {
            NPEData.value = data.results
        }
    } catch (error) {
        logger.error('Error fetching litigation cases', error)
    } finally {
        loadingNPECampaigns.value = false
    }
}

const onGetCasesForNPE = async (npe) => {
    try {
        const params = {
            SC: ['totalDamages'],
            SD: [0],
            FD: [],
            FT: [],
            FV: [],
            page_size: 10,
            page: 1,
        }
        const { data } = await getCasesForNPE(npe.campaignPk, params)
        npe.cases = data.results
    } catch (error) {
        logger.error('Error fetching litigation cases', error)
    } finally {
        npe.loadingCases = false
    }
}

const onShowNPECases = (npe) => {
    if (npe.showNPECases) {
        npe.showNPECases = false
        npe.loadingCases = false
    } else {
        NPEData.value.forEach((element) => (element.showNPECases = false))
        NPEData.value.forEach((element) => (element.loadingCases = false))
        npe.showNPECases = true
        if (!npe.cases) {
            npe.loadingCases = true
            onGetCasesForNPE(npe)
        }
    }
}
</script>

<style lang="scss" scoped>
.npe-campaigns-card {
    width: 100%;

    .heading-background {
        background: rgba($warningBackground, 0.5);
        border-radius: 8px 8px 0 0;
    }

    .remove-padding {
        padding: 0 !important;
    }

    .bellow {
        &:last-of-type {
            .bellow-cta {
                border-radius: 0 0 8px 8px;
                border-bottom: none;
            }
        }

        &.show {
            .bellow-cta {
                background: $grey05;
                border-bottom: 1px solid transparent;
            }
            .bellow-content {
                background: $grey05;
                max-height: 500px;
                overflow: auto;
            }

            .bellow-caret {
                transform: rotate(90deg);
                color: black;
            }
        }

        &.ignore-accordion {
            .bellow-caret {
                display: none;
            }

            .bellow-cta {
                cursor: auto;

                &:hover {
                    background: white;
                }
            }
        }
    }

    .bellow-cta {
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        &:hover {
            background-color: $grey05;
        }
    }

    .bellow-content {
        max-height: 0;
        overflow: hidden;

        transition: all 0.5s ease-in-out;

        &:last-of-type {
            .investment {
                margin-bottom: 0;
            }
        }

        .investment {
            background: white;
        }
    }

    .case-holder {
        max-height: 500px;
        overflow-y: auto;
    }

    .case {
        border: solid 1px $grey04;

        &:last-of-type {
            margin-bottom: 0 !important;
        }

        .stat-item {
            min-width: 100px;
        }
    }
}
</style>
