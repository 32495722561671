<template>
    <div class="arenas">
        <AonContainer add-page-padding class="knights-cloak">
            <AonRow>
                <AonCol class="a-col-12 pt-6 pb-16">
                    <div
                        class="header-holder d-flex w-full justify-content-between align-items-center"
                    >
                        <div class="header-copy-holder">
                            <h4 class="grey08--text">
                                {{ t('arenaList.header.title') }}
                            </h4>
                            <p class="grey08--text">
                                Define key technology areas to evaluate competitive benchmarks
                            </p>
                        </div>
                        <AonButton label="Add Arena" @clicked="toggleNewArenaModal()" />
                        <!-- @clicked="datasetStore.toggleShowOnlyCreateWatchLists()" -->
                    </div>
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonContainer add-page-padding class="mt-n12">
            <AonRow>
                <AonCol class="a-col-12">
                    <ArenaHistoryTable :key="updater" />
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonModal
            v-if="showArenaModal"
            title="Create a new Arena"
            message=""
            col-width="6"
            @close="toggleNewArenaModal()"
        >
            <form @submit.prevent="createArena()">
                <AonInput
                    v-model="form.name"
                    name="name"
                    label="Arena Name"
                    id="name"
                    focus
                    :error="!!v$.name.$errors.length"
                    :error-options="{
                        errorMsg: v$.name.$errors[0] ? v$.name.$errors[0].$message : null,
                    }"
                    @keydown.enter.prevent="createArena()"
                />
                <AonButton
                    class="mt-4"
                    label="Submit"
                    :loading="createNewArenaLoading"
                    is-form-submit
                />
            </form>
        </AonModal>
    </div>
</template>

<script setup>
import { ref, inject, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useArenaStore } from '@/stores'
import { requestArena } from '@/api/arena.js'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'

import ArenaHistoryTable from '@/components/arenaBoard/ArenaHistoryTable'

const eventBus = inject('eventBus')
const logger = inject('logger')
const { t } = useI18n()
const router = useRouter()
const arenaStore = useArenaStore()

const form = reactive({
    name: '',
})
const rules = {
    name: { required, maxLength: maxLength(200) },
}

const v$ = useVuelidate(rules, form)

const updater = ref(0)
const showArenaModal = ref(false)
const createNewArenaLoading = ref(false)
const error = ref(null)

// eventBus.on('updateWatchlistPage', async () => {
//     updater.value++
// })

const createArena = async () => {
    createNewArenaLoading.value = true
    const result = await v$.value.name.$validate()

    if (!result) {
        createNewArenaLoading.value = false
        return
    }

    try {
        const { data } = await requestArena(null, null, form.name)
        arenaStore.rootNodeId = data.rootNodeId
        router.push({ name: 'Arena', params: { arenaSourceId: data.productAlignmentId } })
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    } finally {
        createNewArenaLoading.value = false
    }
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}

const toggleNewArenaModal = () => {
    showArenaModal.value = !showArenaModal.value
}
</script>

<style lang="scss" scoped>
.arenas {
    min-height: 100vh;
    height: 100%;
}
</style>
