<template>
    <div class="peer-history-card">
        <AonCard
            class="h-full d-flex flex-column"
            style="position: relative"
            title="Peer History in Industry"
        >
            <AonCoverLoading
                :loading="loadingData"
                title="Loading Peer History..."
            ></AonCoverLoading>
            <div class="content-holder flex-grow-1">
                <MoatTable
                    class="w-full h-full"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :suppress-auto-size="true"
                    :row-data="tableData"
                    :autoSizeStrategy="sizingStrategy"
                    :sorting-order="['desc', 'asc', null]"
                    :enable-charts="true"
                    :cell-selection="true"
                    :sideBar="sideBar"
                    @grid-ready="gridInit"
                />
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { useEntityStore } from '@/stores'
import { getEntitiesLitigationSummary } from '@/api/ipNode.js'
import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    title: {
        type: String,
        default: '',
    },
    description: {
        type: String,
        default: '',
    },
})

const showCrunchbaseArchOnly = useFlag('ipAlpha.archUsersOnly')

const logger = inject('logger')
const filters = inject('filters')
const entityStore = useEntityStore()
const { t } = useI18n()

const loadingData = ref(true)
const tableData = ref([])
const sideBar = ref(null)
const sizingStrategy = ref({})
const axisRange = ref([0, 10])

const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.definitionTooltips.companyName'),
            headerName: 'Company Name',
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        field: 'entityName',
        headerName: 'Company Name',
        headerTooltip: 'Company Name',
        minWidth: 300,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agTextColumnFilter',
        pinned: 'left',
        lockPosition: true,
        chartDataType: 'category',
    },
    {
        field: 'competitorRank',
        headerName: 'Rank',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.competitorRank.toLocaleString()}`
            }
        },
        headerTooltip: 'Rank',
        minWidth: 120,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agNumberColumnFilter',
        chartDataType: 'category',
    },
    {
        field: 'caseCountHistory',
        headerTooltip: 'Case Count By Year',
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
            sparklineOptions: {
                type: 'column',
                xKey: 'year',
                yKey: 'caseCount',
                fill: '#2A79D2',
                valueAxisDomain: axisRange.value,
                axis: { type: 'number' },
                tooltip: {
                    renderer: (params) => {
                        const { x, y } = params.datum
                        return {
                            title: `${x}:`,
                            content: y,
                        }
                    },
                },
            },
        },
    },
    {
        field: 'casesCount',
        headerName: 'Case Count',
        headerTooltip: 'Case Count',
        minWidth: 150,
        filter: 'agNumberColumnFilter',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.casesCount.toLocaleString()}`
            }
        },
        chartDataType: 'series',
    },
    {
        field: 'casesDefendantCount',
        headerName: 'Defendant Case Count',
        headerTooltip: 'Defendant Case Count',
        minWidth: 240,
        filter: 'agNumberColumnFilter',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.casesDefendantCount.toLocaleString()}`
            }
        },
        chartDataType: 'series',
    },
    {
        field: 'casesPlaintiffCount',
        headerName: 'Plaintiff Case Count',
        headerTooltip: 'Plaintiff Case Count',
        minWidth: 240,
        filter: 'agNumberColumnFilter',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.casesPlaintiffCount.toLocaleString()}`
            }
        },
        chartDataType: 'series',
    },
    {
        field: 'casesNPECount',
        headerName: 'NPE Case Count',
        headerTooltip: 'NPE Case Count',
        minWidth: 240,
        filter: 'agNumberColumnFilter',
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.casesNPECount.toLocaleString()}`
            }
        },
        chartDataType: 'series',
    },
    {
        field: 'avgCaseLength',
        headerName: 'Average Case Length',
        headerTooltip: 'Average Case Length',
        flex: 1,
        minWidth: 220,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.avgCaseLength.toLocaleString()
            }
        },
    },
    {
        field: 'damagesIncurred',
        headerTooltip: 'Damages Paid',
        flex: 1,
        minWidth: 120,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.damagesIncurred)
            }
        },
    },
])

onMounted(() => {
    getTopInnovations()
})

const gridInit = (params) => {
    // If we start added thing for different user groups, make a function to set this
    if (showCrunchbaseArchOnly.value) {
        colDefs.value.splice(2, 0, {
            field: 'crunchbaseRank',
            headerName: 'Crunchbase Rank',
            valueFormatter: (p) => {
                if (p.data) {
                    return p.data.crunchbaseRank ? p.data.crunchbaseRank.toLocaleString() : '--'
                }
            },
            filter: 'agNumberColumnFilter',
            chartDataType: 'category',
        })
    }

    gridApi.value = params.api
    sideBar.value = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    }
}

const getTopInnovations = async () => {
    loadingData.value = true
    try {
        let { data } = await getEntitiesLitigationSummary(props.nodeData.id)
        tableData.value = data.results
    } catch (error) {
        logger.error(error)
    } finally {
        loadingData.value = false
    }
}
</script>

<style lang="scss" scoped>
.peer-history-card {
    width: 100%;
    height: 500px;
    position: relative;
}
</style>
