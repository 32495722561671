<template>
    <div
        class="moat-table-custom-col-header d-flex align-items-center justify-content-between"
        :class="params.ignoreSort ? 'ignore-sort' : ''"
        @click.prevent="onSortRequested('', $event)"
    >
        <div class="front d-flex align-items-center">
            <p class="bold small overflow-ellipsis">{{ params.headerName }}</p>
            <div
                v-tooltip="{
                    content: params.tooltipDefintion,
                    distance: 12,
                    popperClass: 'moat-popper-class',
                }"
            >
                <font-awesome-icon
                    v-if="params.tooltipDefintion"
                    icon="fas fa-circle-info"
                    class="castle-moat--text d-inline metric-icon ml-2"
                />
            </div>
            <div v-if="state.ascSort" class="ml-2">
                <i class="ag-icon ag-icon-asc"></i>
            </div>
            <div v-if="state.descSort" class="ml-2">
                <i class="ag-icon ag-icon-desc"></i>
            </div>
        </div>
        <div
            v-if="params.enableFilterButton"
            class="filter-icon ml-2"
            @click.stop="onMenuClicked($event)"
        >
            <span data-ref="eFilterButton" class="ag-header-icon" aria-hidden="true">
                <span
                    class="ag-icon ag-icon-filter"
                    :class="state.filterActiveClass"
                    style="font-size: 16px; display: block"
                ></span>
            </span>
        </div>
    </div>
</template>

<script setup>
import { reactive, onMounted, inject, ref } from 'vue'

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
})
const eventBus = inject('eventBus')

const state = reactive({
    ascSort: false,
    descSort: false,
    noSort: true,
    tableSortLabel: '',
    filterActiveClass: '',
})

onMounted(() => {
    props.params.column.addEventListener('sortChanged', onSortChanged)
    props.params.column.addEventListener('filterChanged', onFilterChanged)
    onSortChanged()
})

// changes Icons
const onSortChanged = () => {
    state.ascSort = state.descSort = state.noSort = false
    if (props.params.column.sort === 'asc') {
        state.ascSort = true
    } else if (props.params.column.sort === 'desc') {
        state.descSort = true
    } else {
        state.noSort = true
    }
}

const onSortRequested = (order, event) => {
    if (props.params.ignoreSort) {
        return
    }

    props.params.progressSort(event.shiftKey)
    if (props.params.customSortEmitColumn) {
        let sortDirection =
            props.params.column.sort === 'asc' ? 1 : props.params.column.sort === 'desc' ? -1 : 0
        eventBus.emit('sort-requested', {
            column: props.params.customSortEmitColumn,
            direction: sortDirection,
        })
    }
}

const onMenuClicked = () => {
    props.params.api.showColumnFilter(props.params.column.colDef.field)
}

const onFilterChanged = () => {
    if (props.params.column.isFilterActive()) {
        state.filterActiveClass = 'ag-filter-active'
    } else {
        state.filterActiveClass = ''
    }
}
</script>

<style lang="scss" scoped>
.ignore-sort {
    cursor: default !important;
}
.filter-icon {
    &:hover {
        box-shadow: 0 0 0 4px $grey05 !important;
        .ag-icon-filter {
            background: $grey05 !important;
        }
    }
}
</style>
