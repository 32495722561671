<template>
    <div class="select-target-company d-flex flex-column">
        <EntitySelectionTable
            table-heading="Select Target Company"
            :table-sub-heading="props.subHeading"
            persist-selections
            :init-selected-entity="initalTargetCompany"
            @selected-entities="setNodeToAdd"
        />
    </div>
</template>

<script setup>
import { onMounted, nextTick, computed } from 'vue'
import { useReportGenerationStore } from '@/stores'

import EntitySelectionTable from '@/components/helpers/EntitySelectionTable'

const reportGenerationStore = useReportGenerationStore()

const props = defineProps({
    heading: {
        type: String,
        default: 'Select Target Product Arena',
    },
    subHeading: {
        type: String,
        default:
            'Search for and select a company to apply its product and technology areas for comparison.',
    },
})

onMounted(async () => {
    await nextTick()
})

const initalTargetCompany = computed(() => {
    return reportGenerationStore.targetCompany
})

const setNodeToAdd = (row) => {
    reportGenerationStore.setReportCompanySelection(row[0])
}
</script>

<style lang="scss" scoped>
.select-target-company {
    height: 100%;
    width: 100%;
    position: relative;
}
</style>
