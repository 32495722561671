import { createReport, patchReport } from '@/api/reports.js'
import { logger } from '@moatmetrics/vue-logger'

export const createAndSubmitReport = async (
    eventBus,
    user,
    reportType,
    reportName,
    reportInput
) => {
    try {
        const params = buildBaseParams(user, reportType, reportName, reportInput)
        const { data } = await createReport(params)
        await submitReport(data, eventBus)
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message:
                'There has been an error submitting this report. ' +
                'Please contact support@moatmetrics.com for assistance.',
            contextual: false,
        })
    }
}

const buildBaseParams = (user, reportType, reportName, reportInput) => {
    return {
        requested_application: 'ip_alpha',
        requested_user: user.value.id,
        report_type: reportType,
        report_name: reportName,
        report_input: reportInput,
    }
}

const submitReport = async (reportData, eventBus) => {
    const params = {
        report_action: 'submit',
    }
    await patchReport(reportData.report_pk, params)

    eventBus.emit('snacktime', {
        type: 'success',
        message:
            'This report has been submitted. Feel free to continue using the app, ' +
            'or navigate to the reports page to view the status of report..',
        link: {
            text: 'Reports',
            route: '/reports',
        },
        contextual: false,
    })
}
