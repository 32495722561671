<template>
    <div class="execution-tab mb-5">
        <AonSpinner
            v-if="entityStore.loadingEntity"
            class="ma-auto"
            :scale="0.5"
            color="castleMoat"
        />
        <div v-else class="card-holder">
            <AonContainer add-page-padding>
                <AonRow class="mt-5">
                    <AonCol class="aon-col-12 pa-0">
                        <KnowledgeExchangeCard class="mb-5" />
                        <InnovationTrendCard class="mb-5" />
                        <TopInventorsCard class="mb-5" />
                        <ExecutiveTeamCard class="mb-5" />
                    </AonCol>
                </AonRow>
            </AonContainer>
        </div>
    </div>
</template>

<script setup>
import { watch, onMounted } from 'vue'
import { useEntityStore } from '@/stores'

import ExecutiveTeamCard from './ExecutiveTeamCard.vue'
import TopInventorsCard from './TopInventorsCard.vue'
import InnovationTrendCard from './InnovationTrendCard.vue'
import KnowledgeExchangeCard from './KnowledgeExchangeCard.vue'

const entityStore = useEntityStore()

onMounted(async () => {})
</script>

<style lang="scss" scoped>
.execution-tab {
    width: 100%;
    position: relative;
}
</style>
