<template>
    <div class="innovation-focus-card">
        <h5 class="grey01--text">
            What is {{ computedHeader }} doing within {{ props.nodeData.displayName }}?
        </h5>
        <CompanyFocus
            :headerName="computedHeader"
            :headerTicker="computedTicker"
            :nodeData="props.nodeData"
            :entityPk="computedEntityPk"
            :type="props.type"
        />
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useEntityStore, useArenaOutputStore } from '@/stores'

import CompanyFocus from './shared/CompanyFocus.vue'
import { type } from '@amcharts/amcharts5'

const entityStore = useEntityStore()
const arenaOutputStore = useArenaOutputStore()

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})

const store = ref(null)

const computedHeader = computed(() => {
    if (props.type === 'pa') {
        return entityStore.entity.name
    } else {
        return arenaOutputStore.targetCompany[0]?.aonEntityName
    }
})

const computedTicker = computed(() => {
    if (props.type === 'pa') {
        return `${entityStore.entity.stock_ticker} ${entityStore.entity.stock_exchange}`
    } else {
        return ''
    }
})

const computedEntityPk = computed(() => {
    if (props.type === 'pa') {
        return entityStore.entity.aon_entity_pk
    } else {
        return arenaOutputStore.targetCompany[0]?.aonEntityPk
    }
})
</script>

<style lang="scss" scoped>
.innovation-focus-card {
    width: 100%;
}
</style>
