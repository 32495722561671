<template>
    <div class="counter-assertion-playbook">
        <KeepAlive>
            <TargetCompanySelection
                v-if="currentStep.stepNum === 2"
                heading="Select Initiating Party"
                sub-heading="Need something for this area"
                no-data-message="To begin, please search for the company that originally initiated litigation."
            />
        </KeepAlive>
        <KeepAlive>
            <CompetitorSelection
                v-if="currentStep.stepNum === 3"
                heading="Select Responding Party"
                sub-heading="Select responding party against which the initiating party will be compared."
                :stepNum="3"
                :max-selections="1"
                :user="userObject.user"
            />
        </KeepAlive>
        <KeepAlive>
            <CounterAssertionOutput v-if="currentStep.stepNum === 4" flex-table />
        </KeepAlive>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick, computed } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useAuth } from '@/auth/authPlugin'

import TargetCompanySelection from '@/components/playbooks/shared/TargetCompanySelection.vue'
import CompetitorSelection from '../../shared/CompetitorSelection.vue'
import CounterAssertionOutput from './CounterAssertionOutput.vue'

const playbookGenerationStore = usePlaybookGenerationStore()
const router = useRouter()

const updater = ref(0)
const source = ref(null)
const userObject = ref(useAuth())

onMounted(async () => {
    await nextTick()
})

watch(
    () => playbookGenerationStore.reportSelections,
    (newVal, oldVal) => {
        updater.value++
    },
    { deep: true }
)

watch(
    () => router,
    (newVal, oldVal) => {
        source.value = newVal.currentRoute.value.query.source
    },
    { flush: 'pre', immediate: true, deep: true }
)

const currentStep = computed(() => {
    return playbookGenerationStore.getCurrentStep()
})
</script>

<style lang="scss" scoped>
.counter-assertion-playbook {
    height: 100%;
    width: 100%;
}
</style>
