<template>
    <div class="create-report-page">
        <ReportGenerationContainer />
    </div>
</template>

<script setup>
import ReportGenerationContainer from '@/components/reports/ReportGenerationContainer.vue'
import { onUnmounted } from 'vue'
import { useReportGenerationStore } from '@/stores'

const reportGenerationStore = useReportGenerationStore()
</script>

<style lang="scss" scoped>
.create-report-page {
    height: calc(100vh - 65px);
    width: 100%;
}
</style>
