<template>
    <div class="competitor-selection d-flex flex-column">
        <!-- <AonCoverLoading
            :loading="tableLoading"
            title="Loading Company Watchlist..."
        ></AonCoverLoading> -->
        <ContenderSelectionSplit
            v-if="allCompetitors.length > 0"
            class="flex-grow-1"
            :all-competitor-data="allCompetitors"
            :selected-table-data="selectedCompetitors"
            :max-selected-competitors="props.maxSelections"
            :heading-text="props.heading"
            :heading-sub-text="props.subHeading"
            :filter-name-list="[playbookGenerationStore.targetCompany]"
            :preSelectedContenders="[props.user?.entity_pk]"
            @add-selection="addSelection"
            @delete-selection="updateSelections"
        />
    </div>
</template>

<script setup>
import { ref, inject, onMounted, nextTick, computed, onActivated } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { cloneDeep } from 'lodash-es'

import ContenderSelectionSplit from '../../helpers/CompetitorSelectionSplit.vue'

const playbookGenerationStore = usePlaybookGenerationStore()

const logger = inject('logger')

const groupByUltimateParentToggle = ref(true)

const props = defineProps({
    stepNum: {
        type: Number,
        default: 1,
    },
    maxSelections: {
        type: Number,
        default: 10,
    },
    heading: {
        type: String,
        default: 'Select Competitors',
    },
    subHeading: {
        type: String,
        default: `Select competitors to compare to your target product arena`,
    },
    user: {
        type: Object,
        default: null,
    },
})

onMounted(async () => {
    await nextTick()
})

onActivated(async () => {})

const allCompetitors = computed(() => {
    return playbookGenerationStore.allCompetitorData
})

const selectedCompetitors = computed(() => {
    return playbookGenerationStore.playbookSelections[props.stepNum - 1]?.value
})

const addSelection = (competitor) => {
    const currentList = cloneDeep(
        playbookGenerationStore.playbookSelections[props.stepNum - 1].value
    )
    let newCompetitorList = [...currentList, competitor]
    playbookGenerationStore.playbookSelections[props.stepNum - 1].value = newCompetitorList
}

const updateSelections = (competitor) => {
    let newCompetitorList = playbookGenerationStore.playbookSelections[
        props.stepNum - 1
    ].value.filter((item) => item.aonEntityName !== competitor.aonEntityName)

    playbookGenerationStore.playbookSelections[props.stepNum - 1].value = newCompetitorList
}
</script>

<style lang="scss" scoped>
.competitor-selection {
    height: 100%;
    width: 100%;

    position: relative;

    :deep(.search-companies-table) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
</style>
