<template>
    <div class="watchlists">
        <AonContainer add-page-padding class="knights-cloak">
            <AonRow>
                <AonCol class="a-col-12 pt-6 pb-16">
                    <div
                        class="header-holder d-flex w-full justify-content-between align-items-center"
                    >
                        <div class="header-copy-holder">
                            <h4 class="grey08--text">
                                {{ $t('watchlists.title') }}
                            </h4>
                            <p class="grey08--text">
                                Create a collection of companies to save and use as a filter in
                                competitive benchmarking
                            </p>
                        </div>
                        <AonButton
                            label="Add Watchlist"
                            @clicked="datasetStore.toggleShowOnlyCreateWatchLists()"
                        />
                    </div>
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonContainer add-page-padding class="mt-n12">
            <AonRow>
                <AonCol class="a-col-12">
                    <WatchlistList :key="updater" />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { useDatasetStore } from '@/stores'
const datasetStore = useDatasetStore()
import WatchlistList from '@/components/Watchlists/WatchlistList'
import { ref, inject } from 'vue'
const eventBus = inject('eventBus')

const updater = ref(0)

eventBus.on('updateWatchlistPage', async () => {
    updater.value++
})
</script>

<style lang="scss" scoped>
.watchlists {
    min-height: 100vh;
    height: 100%;
}
</style>
