import axios from 'axios'

let baseUrl = `api/dashboard`
let baseUrlV2 = `api/v2.0/dashboard`

export function getCompanyProductStrategy(entityPk) {
    return axios.get(`${baseUrlV2}/${entityPk}/product_strategy_by_year`)
}

export function getTopProductsByInnovation(entityPk) {
    return axios.get(`${baseUrl}/${entityPk}/top_products_by_innovation`)
}
