import * as EntityApi from '@/api/entities'
import * as SearchApi from '@/api/opensearch'
import { logger } from '@moatmetrics/vue-logger'
import { getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import { defineStore } from 'pinia'
import { getExecutiveTeam } from '../api/companytabs/execution'
import {
    getEntityMoatQualityMetrics,
    getEntityValidityByClaimScore,
    computeEntityCompetitors,
    computeEntityAcquisitionTargets,
    computePotentialBuyers,
    getInsights,
} from '../api/entities'

export const useEntityStore = defineStore('entity', {
    state: () => ({
        cachedEntities: {},
        loadingEntity: true,
        loadingMarkets: true,
        entity: null,
        summaryEntity: null,
        entityAggregates: null,
        entityHierarchy: [],
        entityHistory: [],
        entityMarketsHistory: [],
        entityPatentCounts: null,
        entityTabs: [],
        patentCountsByClaim: [],
        entityPatentFamilyStats: null,
        patentBreakdown: null,
        ipBasicsActivePatents: true,
        validityByClaimScore: null,
        targetMarket: null,
        ipValue: null,
        ipRebuildCost: null,
        financialOverview: null,
        executiveTeam: null,
        moatQualityMetrics: null,
        competitors: null,
        acquisitionTargets: null,
        potentialBuyers: null,
        showDrillIn: false,
        drillInTargetId: null,
        insights: null,
    }),
    getters: {
        getCachedEntity: (state) => (entityId) => {
            if (!entityId) {
                return
            }

            const entity = state.cachedEntities[entityId]

            return entity ? { ...entity } : null
        },
        getPatentCountsByClaim: (state) => {
            return state.patentCountsByClaim
        },
    },
    actions: {
        async resetState() {
            this.$reset()
        },
        setCachedEntities(cachedEntities) {
            // TODO validate entities?
            this.cachedEntities = cachedEntities
        },
        updateOrAddCachedEntity(entity) {
            if (!entity) {
                logger.warn(
                    `Warning: entity is required for EntityStore.updateOrAddCachedEntity(entity)`
                )
                return
            }

            const cachedEntities = { ...this.cachedEntities }

            // TODO validate entity
            cachedEntities[entity.aon_entity_pk] = { ...entity }
            this.setCachedEntities(cachedEntities)
        },
        setEntity(entity) {
            // TODO validate entity
            this.entity = entity
        },
        setSummaryEntity(entity) {
            // TODO validate entity
            this.summaryEntity = entity
        },
        setEntityAggregates(data) {
            this.entityAggregates = data.sort((a, b) => b.total - a.total)
        },
        setEntityHierarchy(entityHierarchy) {
            this.entityHierarchy = entityHierarchy
        },
        setEntityHistory(entityHistory) {
            this.entityHistory = entityHistory
        },
        setEntityMarketsHistory(entityMarketsHistory) {
            this.entityMarketsHistory = entityMarketsHistory
        },
        resetEntityPatentCountsByClaim() {
            this.patentCountsByClaim = []
        },
        setEntityPatentCountsByClaim(counts) {
            this.patentCountsByClaim = counts
        },
        setEntityPatentFamilyStats(entityPatentFamilyStats) {
            this.entityPatentFamilyStats = entityPatentFamilyStats
        },
        setFinancialOverview(financialOverview) {
            this.financialOverview = financialOverview
        },
        setIpValue(ipValue) {
            this.ipValue = ipValue
        },
        setIpRebuildCost(ipRebuildCost) {
            this.ipRebuildCost = ipRebuildCost
        },
        clearFilteredData() {
            this.participatingMarketsFiltered.clear()
        },
        clearSelectedData() {
            this.participatingMarketsSelected.clear()
        },
        addFilteredData(val) {
            this.participatingMarketsFiltered.add(val)
        },
        addSelectedData(val) {
            this.participatingMarketsSelected.add(val)
        },
        removeFilteredData(val) {
            this.participatingMarketsFiltered.delete(val)
        },
        removeSelectedData(val) {
            this.participatingMarketsSelected.delete(val)
        },
        // TODO audit get actions that are actually state getters and move appropiately
        async getEntity(entityId) {
            // TODO use cachedEntity if it exists
            // checkCached=false
            // const foundEntity = this.getCachedEntity[entityId]

            // if (checkCached && foundEntity) {
            //     this.setEntity(foundEntity)
            //     return foundEntity
            // }

            try {
                const dslQuery = getOpenSearchQuery({
                    FC: ['aon_entity_pk'],
                    FT: ['eq'],
                    FV: [entityId],
                })
                const { data } = await SearchApi.searchEntities(dslQuery)
                const entity = data.hits.hits.map((h) => h._source)[0]

                this.setEntity(entity)
                this.updateOrAddCachedEntity(entity)

                return entity
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getEntityHierarchy(entityId) {
            try {
                const dslQuery = getOpenSearchQuery({
                    FC: ['ultimate_aon_entity_pk'],
                    FT: ['eq'],
                    FV: [entityId],
                    SC: ['name'],
                    SD: [1],
                    page_size: 3000,
                })
                const { data } = await SearchApi.searchEntities(dslQuery)
                const entitiesInHierarchy = data.hits.hits.map((h) => h._source)

                this.setEntityHierarchy(entitiesInHierarchy)

                return entitiesInHierarchy
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getEntityPatentFamilyStats(entityId) {
            try {
                const { data } = await EntityApi.getEntityPatentFamilyStats(entityId)

                this.setEntityPatentFamilyStats(data)

                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getFinancialOverview(entityId) {
            try {
                if (this.financialOverview) return this.financialOverview
                const { data } = await EntityApi.getFinancialOverview(entityId)
                this.financialOverview = data
                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getIpValue(entityId) {
            try {
                const { data } = await EntityApi.getIpValue(entityId)
                this.setIpValue(data.ipValuationBin)
                return data.ipValuationBin
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getIpRebuildCost(entityId) {
            try {
                const { data } = await EntityApi.getIpRebuildCost(entityId)
                this.setIpRebuildCost(data.ipRebuildCostBin)
                return data.ipRebuildCostBin
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getPatentBreakdown(entityId) {
            try {
                if (this.patentBreakdown) return this.patentBreakdown

                const { data } = await EntityApi.getPatentBreakdown(entityId)
                this.patentBreakdown = data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getValidityByClaimScore(entityId) {
            try {
                if (this.validityByClaimScore != null) return this.validityByClaimScore

                const { data } = await getEntityValidityByClaimScore(entityId)
                this.validityByClaimScore = data
                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getExecutiveTeam(entityId) {
            try {
                if (this.executiveTeam) return this.executiveTeam
                const { data } = await getExecutiveTeam(entityId)
                this.executiveTeam = data
                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getMoatQualityMetrics(entityId) {
            try {
                if (this.moatQualityMetrics) return this.moatQualityMetrics
                const { data } = await getEntityMoatQualityMetrics(entityId)
                this.moatQualityMetrics = data
                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getCompetitors(entityId) {
            try {
                if (this.competitors) return this.competitors
                const { data } = await computeEntityCompetitors(entityId)
                if (data.Status === 'complete') {
                    this.competitors = data
                }
                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getAcquisitionTargets(entityId) {
            try {
                if (this.acquisitionTargets) return this.acquisitionTargets
                const { data } = await computeEntityAcquisitionTargets(entityId)
                if (data.Status === 'complete') {
                    this.acquisitionTargets = data
                }
                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getPotentialBuyers(entityId) {
            try {
                if (this.potentialBuyers) return this.potentialBuyers
                const { data } = await computePotentialBuyers(entityId)
                if (data.Status === 'complete') {
                    this.potentialBuyers = data
                }
                return data
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
        async getInsights(entityId, insightTypes) {
            try {
                if (this.insights)
                    return this.insights.results.filter((insight) =>
                        insightTypes.includes(insight.insightType)
                    )
                const { data } = await getInsights(entityId)
                this.insights = data
                return data.results
            } catch (err) {
                logger.error(err)
                throw err
            }
        },
    },
})
