<template>
    <div class="patent-analysis-card">
        <AonCard
            title="Patent Analysis"
            desc="Dive into the details of patent activity. This visualization helps you understand trends, strengths, and gaps in patent portfolios."
        >
            <div class="card-content d-flex flex-column justify-content-center w-full mt-4">
                <div class="switch-holder align-self-end d-flex align-items-center move">
                    <p class="mr-3">Filing Date</p>
                    <AonToggleSwitch
                        label="Publication Date"
                        label-end
                        :uniqueID:="'patent-analysis'"
                        :switchToggled="viewByPublicationDate"
                        @switch="toggleDate"
                    />
                </div>
                <AonSpinner
                    v-if="loading"
                    :scale="0.5"
                    class="ml-auto mr-auto mt-16 w-full"
                ></AonSpinner>
                <div class="chart-container">
                    <div
                        v-show="!loading"
                        id="amChartPatentAnalysis"
                        ref="amChartPatentAnalysis"
                    ></div>
                    <p class="y-axis-label bold">Filing Count</p>
                    <p class="x-axis-label bold">Year</p>
                </div>
                <div class="chart-container mt-4">
                    <div v-show="!loading" id="amChartCumulative" ref="amChartCumulative"></div>
                    <p class="y-axis-label2 bold">Cumulative Count</p>
                    <p class="x-axis-label2 bold">Year</p>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from 'vue'
import { useEntityStore } from '@/stores'
import * as entityApi from '@/api/entities'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const entityStore = useEntityStore()

const viewByPublicationDate = ref(false)
const amChartPatentAnalysis = ref(null)
const amChartCumulative = ref(null)
const today = new Date().getFullYear()
const years = [today - 7, today - 6, today - 5, today - 4, today - 3, today - 2, today - 1, today]
const loading = ref(true)
const chartData = ref([])

let root
let chart
let cumulativeRoot
let cumulativeChart
let xAxis
let yAxis
let cumulativeXAxis
let cumulativeYAxis
let legend
let cumulativeLegend

onMounted(async () => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new(amChartPatentAnalysis.value)
    cumulativeRoot = am5.Root.new(amChartCumulative.value)
    fetchAndMapChartData()
})

onUnmounted(() => {
    root.dispose()
    cumulativeRoot.dispose()
})

async function fetchAndMapChartData() {
    let countsRequest = entityApi.getEntityPatentCountHistoryByCountry(
        entityStore.entity.aon_entity_pk,
        'WORLD'
    )
    let cumulativeRequest = entityApi.getEntityCumulativePatentCountHistoryByCountry(
        entityStore.entity.aon_entity_pk,
        'WORLD'
    )

    const [counts, cumulative] = await Promise.all([countsRequest, cumulativeRequest])

    const appCounts = counts.data.filter((c) => c.assetStatus === 'application')
    const grantCounts = counts.data.filter((c) => c.assetStatus === 'grant')
    const abandonedAppCounts = counts.data.filter((c) => c.assetStatus === 'abandoned_application')
    const expiredGrantCounts = counts.data.filter((c) => c.assetStatus === 'expired_grant')

    years.forEach((year) => {
        const app = appCounts.find((a) => a.year === year)
        const grant = grantCounts.find((g) => g.year === year)
        const abandonedApp = abandonedAppCounts.find((a) => a.year === year)
        const expiredGrant = expiredGrantCounts.find((a) => a.year === year)
        const cumulativeAsset = cumulative.data.find((g) => g.year === year)
        const cumulativeCount = cumulativeAsset
            ? viewByPublicationDate.value
                ? cumulativeAsset.publicationYearCount
                : cumulativeAsset.filingYearCount
            : 0

        const getCount = (item) => {
            if (!item) return 0
            return viewByPublicationDate.value ? item.publicationYearCount : item.filingYearCount
        }

        chartData.value.push({
            year: year.toString(),
            applications: getCount(app),
            granted: getCount(grant),
            abandonedApplications: getCount(abandonedApp),
            expired: -1 * getCount(expiredGrant),
            cumulative: cumulativeCount,
        })
    })
    loading.value = false
    configureChart()
    configureCumulativeChart()
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            layout: root.verticalLayout,
            arrangeTooltips: false,
            paddingLeft: 50,
            paddingRight: 50,
        })
    )

    legend = chart.children.push(
        am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            fillField: 'color',
            paddingTop: 50,
            paddingLeft: 50,
            width: 500,
            centerItems: true,
        })
    )

    configureAxes()
}

const configureCumulativeChart = () => {
    cumulativeRoot.setThemes([am5themes_Animated.new(cumulativeRoot)])

    cumulativeChart = cumulativeRoot.container.children.push(
        am5xy.XYChart.new(cumulativeRoot, {
            panX: false,
            panY: false,
            layout: cumulativeRoot.verticalLayout,
            arrangeTooltips: false,
            paddingLeft: 50,
            paddingRight: 50,
        })
    )

    cumulativeLegend = cumulativeChart.children.push(
        am5.Legend.new(cumulativeRoot, {
            centerX: am5.p50,
            x: am5.p50,
            fillField: 'color',
            paddingTop: 50,
        })
    )

    configureCumulativeAxes()
}

const configureAxes = () => {
    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: am5xy.AxisRendererX.new(root, {
                minGridDistance: 60,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                strokeOpacity: 0.1,
            }),
        })
    )

    xAxis.data.setAll(chartData.value)

    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            extraMin: 0.15,
            extraMax: 0.15,
            renderer: am5xy.AxisRendererY.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 60,
            }),
        })
    )

    generateSeries('Applications', 'applications', 'down')
    generateSeries('Granted', 'granted', 'down')
    generateSeries('Abandoned Applications', 'abandonedApplications', 'down')
    generateSeries('Expired', 'expired', 'up')

    var cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}))
    cursor.lineY.set('forceHidden', true)
    cursor.lineX.set('forceHidden', true)
}

const configureCumulativeAxes = () => {
    cumulativeXAxis = cumulativeChart.xAxes.push(
        am5xy.CategoryAxis.new(cumulativeRoot, {
            categoryField: 'year',
            renderer: am5xy.AxisRendererX.new(cumulativeRoot, {
                minGridDistance: 60,
                strokeOpacity: 0.1,
            }),
        })
    )

    cumulativeXAxis.data.setAll(chartData.value)

    cumulativeYAxis = cumulativeChart.yAxes.push(
        am5xy.ValueAxis.new(cumulativeRoot, {
            renderer: am5xy.AxisRendererY.new(cumulativeRoot, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 60,
            }),
        })
    )

    generateCumulativeSeries()

    let cursor = cumulativeChart.set('cursor', am5xy.XYCursor.new(cumulativeRoot, {}))
    cursor.lineY.set('forceHidden', true)
    cursor.lineX.set('forceHidden', true)
}

const generateSeries = (name, fieldName, tooltipPosition) => {
    let color
    if (name === 'Applications') {
        color = am5.color('#2A79D2')
    } else if (name === 'Granted') {
        color = am5.color('#359B8E')
    } else if (name === 'Abandoned Applications') {
        color = am5.color('#F2AF3A')
    } else {
        color = am5.color('#E06259')
    }

    var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            fill: color,
            valueYField: fieldName,
            categoryXField: 'year',
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: tooltipPosition,
                labelText: '{categoryX}: {valueY}',
            }),
        })
    )

    series.columns.template.setAll({
        width: am5.percent(85),
        strokeOpacity: 0,
    })

    series.bullets.push(function () {
        var label = am5.Label.new(root, {
            text: '{valueY}',
            centerX: am5.p50,
            centerY: am5.p100,
            populateText: true,
            fontSize: 12,
            fontWeight: 'bold',
        })

        label.adapters.add('centerY', function (centerY, target) {
            var dataItem = target.dataItem
            if (dataItem) {
                var value = dataItem.get('valueY', 0)
                if (value < 0) {
                    return 0
                }
            }

            return centerY
        })

        return am5.Bullet.new(root, {
            locationY: 1,
            sprite: label,
        })
    })

    series.data.setAll(chartData.value)
    series.appear()

    legend.data.push(series)
}

const generateCumulativeSeries = () => {
    let cumulativeSeries = cumulativeChart.series.push(
        am5xy.LineSeries.new(cumulativeRoot, {
            name: 'Cumulative',
            xAxis: cumulativeXAxis,
            yAxis: cumulativeYAxis,
            valueYField: 'cumulative',
            categoryXField: 'year',
            maskBullets: false,
            stroke: am5.color('#000'),
            fill: am5.color('#000'),
            tooltip: am5.Tooltip.new(cumulativeRoot, {
                labelText: '{categoryX}: {valueY}',
            }),
        })
    )

    cumulativeSeries.bullets.push(function () {
        return am5.Bullet.new(cumulativeRoot, {
            locationY: 1,
            sprite: am5.Circle.new(cumulativeRoot, {
                fill: am5.color('#000'),
                radius: 6,
            }),
        })
    })

    cumulativeSeries.strokes.template.setAll({
        strokeWidth: 2,
    })

    cumulativeSeries.data.setAll(chartData.value)
    cumulativeSeries.appear()

    cumulativeLegend.data.push(cumulativeSeries)
}

const toggleDate = () => {
    viewByPublicationDate.value = !viewByPublicationDate.value

    // Remove existing series, axes, and legends
    chart.xAxes.clear()
    chart.yAxes.clear()
    chart.series.clear()

    cumulativeChart.xAxes.clear()
    cumulativeChart.yAxes.clear()
    cumulativeChart.series.clear()

    // Reset chart data array
    chartData.value = []

    // Fetch new data
    fetchAndMapChartData()

    // Update existing series data
    chart.series.each((series) => {
        series.data.setAll(chartData.value)
    })

    cumulativeChart.series.each((series) => {
        series.data.setAll(chartData.value)
    })
}
</script>

<style lang="scss" scoped>
.patent-analysis-card {
    width: 100%;
    position: relative;

    :deep(.a-toggle-switch label) {
        background: $castleMoat !important;
    }

    :deep(.a-card-desc) {
        margin-top: 20px !important;
    }

    .move {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .chart-container {
        position: relative;
        height: 500px;
        width: 100%;
    }

    #amChartPatentAnalysis,
    #amChartCumulative {
        width: 100%;
        height: 100%;
        min-height: 100px;
        div {
            height: 100%;
        }
    }

    .y-axis-label {
        position: absolute;
        left: 40px;
        top: 60%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }

    .y-axis-label2 {
        position: absolute;
        left: 40px;
        top: 60%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }

    .x-axis-label {
        position: absolute;
        left: 50%;
        bottom: 90px;
        transform: translateX(-50%);
    }
    .x-axis-label2 {
        position: absolute;
        left: 50%;
        bottom: 70px;
        transform: translateX(-50%);
    }
}
</style>
