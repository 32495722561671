<template>
    <div class="snackbar elevation-5">
        <transition name="snackbar">
            <AonAlertBox
                v-if="snackbar"
                :is-contextual="isContextual"
                v-model="snackbar"
                :type="type"
                :title="title"
                can-close
                :linkURL="linkRoute"
                :link-text="linkMessage ? 'View' : ''"
                :message="linkMessage"
                @close="snackbar = false"
            />
        </transition>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { inject } from 'vue'

const eventBus = inject('eventBus')

const snackbar = ref(false)
const type = ref('info')
const timeout = ref(10000)
const title = ref('')
const description = ref('')
const linkMessage = ref('')
const linkRoute = ref('')
const isContextual = ref(true)
const alertOptions = ref(null)

onMounted(() => {
    eventBus.on('snacktime', updateSnackbar)
})

onUnmounted(() => {
    eventBus.off('snacktime')
})

const updateSnackbar = (options) => {
    const {
        color = 'info',
        type: optionType,
        timeout: optionTimeout = 10000,
        persistent,
        title: optionTitle,
        message,
        description: optionDescription,
        ...additional
    } = options

    type.value = optionType || color
    timeout.value = optionTimeout
    title.value = optionTitle || message
    description.value = optionDescription
    alertOptions.value = additional
    linkMessage.value = additional.link?.text || ''
    linkRoute.value = additional.link?.route
    isContextual.value = additional.contextual

    snackbar.value = true

    if (!persistent) {
        initTimeout()
    }
}

const initTimeout = () => {
    setTimeout(() => {
        snackbar.value = false
    }, timeout.value)
}
</script>

<style lang="scss" scoped>
.snackbar {
    position: fixed;
    top: 8rem;
    right: 1rem;
    max-width: 30rem;
    width: 100%;
    z-index: 9000;

    .snackbar-enter-active,
    .snackbar-leave-active {
        transition:
            opacity 250ms,
            transform 250ms;
    }

    .snackbar-enter,
    .snackbar-leave-to {
        opacity: 0;
        transform: translateX(2rem);
    }
}
</style>
