<template>
    <div class="description-wrap" :class="dark ? 'description-dark' : 'description-light'">
        <p class="description-text">
            {{ trimmedDescription }}
            <a
                v-if="description && description.length > 400"
                class="castle-moat--text read-more"
                @click="readMore = !readMore"
            >
                {{ $t('entityHeader.readMore') }}
            </a>
        </p>
        <AonModal v-if="readMore" :title="title" @close="cancelDescriptionDialog()">
            <p :key="`description_${i}`" v-for="(section, i) in description.split(/\\n/g)">
                {{ section }}
            </p>
        </AonModal>
    </div>
</template>

<script>
export default {
    name: 'Description',
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        dark: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            readMore: false,
        }
    },
    computed: {
        trimmedDescription() {
            if (!this.description) {
                return ''
            }
            if (this.description.length > 500) {
                return `${this.description.slice(0, 500).replace(/\\n/g, ' ').trimEnd()}...`
            } else {
                return `${this.description.slice(0, 500).replace(/\\n/g, ' ').trimEnd()}`
            }
        },
    },
    methods: {
        cancelDescriptionDialog() {
            this.readMore = false
        },
    },
}
</script>

<style lang="scss" scoped>
.description-wrap {
    p {
        line-height: 1.5rem;
    }
    &.description-dark {
        .description-text {
            color: white;
        }
    }

    &.description-light {
        .description-text {
            color: $grey01;
        }
    }
}
</style>
