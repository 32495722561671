<template>
    <div class="innovation-trends-risk-card">
        <AonContainer add-page-padding class="h-full">
            <AonRow class="mt-5 h-full">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center h-full">
                    <AonCard
                        :title="`${entityStore.entity.name}'s Litigation Risk Gaps`"
                        :desc="`Products and technologies which have no identified litigation protection.`"
                        class="h-full"
                    >
                        <div class="card-content" style="height: 500px">
                            <AonCoverLoading
                                :loading="props.loading"
                                title="Loading Trend Risk..."
                                message="Products and Technology Areas within Moat2Product will need to be identified."
                            ></AonCoverLoading>
                            <MoatTable
                                v-if="axisRange.length > 0 && !noRiskTrends && !props.loading"
                                class="w-full h-full"
                                :key="`tech_areas_${tableUpdater}`"
                                :class="themeClass"
                                :column-defs="colDefs"
                                :tooltip-interaction="true"
                                :tooltip-mouse-track="true"
                                :tooltip-show-delay="500"
                                tooltip-show-mode="whenTruncated"
                                :autoGroupColumnDef="autoGroupColumnDef"
                                :groupRemoveLowestSingleChildren="true"
                                :groupDisplayType="groupDisplayType"
                                :treeData="true"
                                :getDataPath="getDataPath"
                                :row-data="props.data"
                                @grid-ready="gridInit"
                                @first-data-rendered="getMaxAndFilterData"
                                @cell-clicked="onCellClicked"
                            />
                            <AonCoverLoading
                                v-if="noRiskTrends"
                                :loading="true"
                                :show-spinner="false"
                                :title="`No Risk Trends Found`"
                            >
                                <template #header>
                                    <div class="icon">
                                        <font-awesome-icon
                                            icon="fas fa-circle-exclamation"
                                            class="grey01--text"
                                            size="2xl"
                                        />
                                    </div>
                                </template>
                            </AonCoverLoading>
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { ref, inject, onBeforeMount, nextTick } from 'vue'
import { useEntityStore } from '@/stores'
import { useI18n } from 'vue-i18n'

import { useFlag } from '@unleash/proxy-client-vue'
const allowDrillIn = useFlag('ipAlph.drillIn')

// Moat Table specific
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'

// injectors
const filters = inject('filters')
const { t } = useI18n()

const entityStore = useEntityStore()

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    data: {
        type: Array,
        default: () => [],
    },
})
const noRiskTrends = ref(false)
const gridApi = ref(null)
const tableUpdater = ref(0)
const finalTableData = ref([])
const getDataPath = ref(null)
const axisRange = ref([0, 10])
const colDefs = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.riskScore'),
            headerName: 'Risk Score',
        },
        field: 'riskScore',
        headerTooltip: 'Risk Score',
        flex: 1,
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.caseCountbyYear'),
            headerName: 'Case Count By Year',
        },
        field: 'caseCountHistory',
        headerTooltip: 'Case Count By Year',
        flex: 1,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
            sparklineOptions: {
                type: 'column',
                xKey: 'year',
                yKey: 'caseCount',
                fill: '#2A79D2',
                valueAxisDomain: axisRange.value,
                axis: { type: 'number' },
                tooltip: {
                    renderer: (params) => {
                        const { x, y } = params.datum
                        return {
                            title: `${x}:`,
                            content: y,
                        }
                    },
                },
            },
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.caseCount'),
            headerName: 'Case Count',
        },
        field: 'caseCount',
        headerTooltip: 'Case Count',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.caseCount.toLocaleString()
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.avgCaseLength'),
            headerName: 'Average Case Length',
        },
        field: 'avgCaseLength',
        headerTooltip: 'Average Case Length',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.avgCaseLength.toLocaleString()
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.patentsAsserted'),
            headerName: 'Patents Asserted',
        },
        field: 'patentCount',
        headerTooltip: 'Patents Asserted',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.patentCount.toLocaleString()
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.knownDamages'),
            headerName: 'Known Damages',
        },
        field: 'totalDamages',
        headerTooltip: 'Known Damages',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.totalDamages)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.highestKnownDamages'),
            headerName: 'Highest Known Damages',
        },
        field: 'maxIndividualDamages',
        headerTooltip: 'Highest Known Damages',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.maxIndividualDamages)
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.npeCases'),
            headerName: 'NPE Cases',
        },
        field: 'npeCaseCount',
        headerTooltip: 'NPE Cases',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.npeCaseCount.toLocaleString()
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('risk.tooltips.npeEntities'),
            headerName: 'NPE Entities',
        },
        field: 'npeEntityCount',
        headerTooltip: 'NPE Entities',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.npeEntityCount.toLocaleString()
            }
        },
    },
])
const autoGroupColumnDef = ref(null)
const groupDisplayType = ref(null)
const themeClass = ref('ag-theme-quartz')

onBeforeMount(() => {
    defineTableGroups()
    getDataPath.value = (data) => {
        return data.nodeHierarchy.map((item) => item)
    }
    groupDisplayType.value = 'singleColumn'
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const getMaxAndFilterData = (params) => {
    // Filter data down
    finalTableData.value = props.data.filter((item) => item.isInnovationOpportunity)

    if (finalTableData.value.length === 0) {
        noRiskTrends.value = true
    } else {
        gridApi.value.setGridOption('rowData', finalTableData.value)
    }

    // Max value for sparkline
    const max = Math.max(...props.data.map((item) => item.caseCount))
    axisRange.value = [0, max]

    gridApi.value.setGridOption('columnDefs', colDefs.value)
    gridApi.value.refreshCells({ force: true })

    params.api.expandAll()
}

const onCellClicked = async (params) => {
    if (!allowDrillIn.value || params.data.nodeType === 'company') {
        return
    }
    entityStore.drillInTargetId = params.data.nodeId
    await nextTick()
    entityStore.showDrillIn = true
}

const defineTableGroups = () => {
    autoGroupColumnDef.value = {
        minWidth: 450,
        field: 'displayName',
        tooltipField: 'displayName',
        suppressMovable: true,
        lockPosition: 'left',
        headerName: 'Product',
        cellRendererParams: {
            suppressCount: true,
        },
        cellClass: (params) => {
            if (params.data) {
                let allowConditions =
                    allowDrillIn.value &&
                    params.value !== entityStore.entity?.name &&
                    params.data.nodeType !== 'company'

                return allowConditions ? 'cell-link' : ''
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.innovation-trends-risk-card {
    width: 100%;
    position: relative;
}
</style>
