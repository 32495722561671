<template>
    <div class="summary-card">
        <AonCard>
            <div class="card-content">
                <div class="summary-details d-flex">
                    <div class="summary-left mr-5">
                        <h6 class="mb-2">About {{ entityStore.entity.name }}</h6>
                        <Description
                            :title="entityStore.entity.name"
                            :description="entityStore.entity.description"
                            :dark="false"
                        />
                    </div>
                    <div class="summary-right">
                        <div v-if="entityStore.entity.entity_type" class="meta">
                            <font-awesome-icon
                                icon="fas fa-flag"
                                class="grey01--text d-inline metric-icon mr-4"
                                size="lg"
                            />
                            <p class="grey01--text bold d-inline">
                                {{ entityStore.entity.entity_type }}
                            </p>
                        </div>
                        <div v-if="entityStore.entity.employee_count" class="meta">
                            <font-awesome-icon
                                icon="fas fa-people-group"
                                class="grey01--text d-inline metric-icon mr-4"
                                size="lg"
                            />
                            <p class="grey01--text bold d-inline">
                                {{ formatEmployeeCount(entityStore.entity.employee_count) }}
                            </p>
                        </div>
                        <div class="meta address d-flex flex-wrap">
                            <div v-if="entityStore.entity.address" class="grey01--text d-flex">
                                <font-awesome-icon
                                    icon="fas fa-map-marker-alt"
                                    class="grey01--text d-inline metric-icon mr-4"
                                    size="lg"
                                />
                                <p class="grey01--text bold">
                                    {{ entityStore.entity.address.replace(/,/g, ', ') }}
                                </p>
                            </div>
                        </div>
                        <div class="meta">
                            <font-awesome-icon
                                icon="fas fa-sack-dollar"
                                class="grey01--text d-inline metric-icon mr-4"
                                size="lg"
                            />
                            <p class="grey01--text d-inline bold">
                                {{ totalRevenue }} ({{ revenueYear ? revenueYear : 'N/A' }})
                            </p>
                        </div>
                        <div v-if="entityStore.entity.homepage_url" class="meta grey01--text">
                            <font-awesome-icon
                                icon="fas fa-arrow-up-right-from-square"
                                class="grey01--text d-inline metric-icon mr-4"
                                size="lg"
                            />
                            <a
                                :href="entityStore.entity.homepage_url"
                                target="_blank"
                                class="link d-inline"
                                style="font-weight: 700"
                            >
                                {{ entityStore.entity.homepage_url }}
                            </a>
                        </div>
                        <div v-if="showCrunchbaseArchOnly">
                            <AonSpinner v-if="loadingCrunchbase" :scale="0.3" />
                            <div v-if="!loadingCrunchbase && crunchbaseRank" class="meta">
                                <font-awesome-icon
                                    icon="fas fa-ranking-star"
                                    class="grey01--text d-inline metric-icon mr-4"
                                    size="lg"
                                />
                                <p class="grey01--text bold d-inline">
                                    Crunchbase Rank: {{ crunchbaseRank.toLocaleString() }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { computed, ref, inject, onBeforeUnmount, onMounted } from 'vue'
import { useEntityStore } from '@/stores'
import { getCrunchbaseRank } from '@/api/entities'
import moment from 'moment'
import { useFlag } from '@unleash/proxy-client-vue'

import Description from '@/components/Description'

const entityStore = useEntityStore()
const filters = inject('filters')
const logger = inject('logger')
const showCrunchbaseArchOnly = useFlag('ipAlpha.archUsersOnly')

const loadingCrunchbase = ref(true)
const crunchbaseRank = ref(null)

onMounted(() => {
    onGetCrunchbaseRank()
})

const totalRevenue = computed(() => {
    return !entityStore.entity || !entityStore.entity.total_revenue
        ? null
        : `${filters.abbreviate(parseInt(entityStore.entity.total_revenue))}`
})

const revenueYear = computed(() => {
    if (entityStore.entity && entityStore.entity.total_revenue) {
        return moment(entityStore.entity.revenue_end_date).format('YYYY')
    }
    return null
})

const formatEmployeeCount = (count) => {
    if (!count) return null

    const hasPlus = count.toString().endsWith('+')
    const numberPart = parseInt(count.toString().replace('+', ''))

    return hasPlus ? `${numberPart.toLocaleString()}+` : numberPart.toLocaleString()
}

const onGetCrunchbaseRank = async () => {
    try {
        const { data } = await getCrunchbaseRank(entityStore.entity.aon_entity_pk)
        crunchbaseRank.value = data.rank
    } catch (err) {
        logger.error(err)
        eventBus.emit('snacktime', {
            type: 'error',
            message: 'An error occurred while fetching crunchbase rank',
        })
    } finally {
        loadingCrunchbase.value = false
    }
}
</script>

<style lang="scss" scoped>
.summary-card {
    width: 100%;
    position: relative;

    .summary-left,
    .summary-right {
        width: 50%;
    }

    .meta {
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .metric-icon {
            width: 25px;
        }
    }
}
</style>
