<template>
    <div class="innovator-table card-content d-flex flex-column h-full">
        <AonCard>
            <div class="card-content" style="min-height: 300px; position: relative">
                <AonCoverLoading
                    :loading="loadingInnovators"
                    :title="`Fetching Top Innovators for ${nodeData.displayName}`"
                ></AonCoverLoading>
                <div v-show="!loadingInnovators" class="card-content-inner">
                    <MoatTable
                        class="flex-grow-1"
                        style="width: 100%; height: 300px"
                        :class="themeClass"
                        :column-defs="colDefs"
                        :row-data="innovatorData"
                        :tooltip-interaction="true"
                        :tooltip-mouse-track="true"
                        :tooltip-show-delay="500"
                        tooltip-show-mode="whenTruncated"
                        :autoSizeStrategy="sizingStrategy"
                        :sorting-order="['desc', 'asc', null]"
                        :selection-column-def="selectionColumnDef"
                        @grid-ready="gridInit"
                    />
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMoat2ProductStore } from '@/stores'
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { getCompetitorsByProduct } from '@/api/productAlignment.js'
import _ from 'lodash'

const { t } = useI18n()
const moat2ProductStore = useMoat2ProductStore()

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
})

const logger = inject('logger')
let innovatorData = ref([])
const loadingInnovators = ref(false)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({})
const selectionColumnDef = ref({
    suppressHeaderMenuButton: true,
    maxWidth: 40,
})
const selectedRows = ref([])

const colDefs = ref([
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: `${t('entityInnovator.pioneers')} (Priority Date)`,
            showFilterButton: true,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: (params) => ({
            entityOverwrite: params.data?.pioneerEntity,
        }),
        field: 'pioneerEntityName',
        headerName: t('entityInnovator.pioneers'),
        minWidth: 180,
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
    },
    // {
    //     headerComponent: 'MTCustomColHeader',
    //     headerComponentParams: {
    //         headerName: t('entityInnovator.emergingChallengers'),
    //     },
    //
    //     field: 'emergingEntityName',
    //     headerName: t('entityInnovator.emergingChallengers'),
    //     minWidth: 180,
    //     flex: 1,
    //     sortingOrder: ['asc', 'desc', null],
    // },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: `${t('entityInnovator.leadingIpHolders')} (Patent Count)`,
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: (params) => ({
            entityOverwrite: params.data?.leadingIpHolderEntity,
        }),
        field: 'leadingIpHolder',
        headerName: t('entityInnovator.leadingIpHolders'),
        flex: 1,
        minWidth: 180,
        sortingOrder: ['asc', 'desc', null],
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: t('entityInnovator.highestMomentum'),
        },
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: (params) => ({
            entityOverwrite: params.data?.highestMomentumEntity,
        }),
        field: 'highestMomentum',
        headerName: t('entityInnovator.highestMomentum'),
        flex: 1,
        minWidth: 180,
        sortingOrder: ['asc', 'desc', null],
    },
])

onMounted(async () => {
    await loadInnovatorData(props.nodeData.nodeId)
})

const gridInit = (params) => {
    gridApi.value = params.api
}

const getTop5 = (data, key, order = 'asc') => {
    const fiftyYearsAgo = new Date()
    fiftyYearsAgo.setFullYear(fiftyYearsAgo.getFullYear() - 100)

    return _.chain(data)
        .filter((item) => new Date(item[key]) > fiftyYearsAgo)
        .orderBy([key], [order])
        .slice(0, 5)
        .value()
}

const loadInnovatorData = async (nodeId) => {
    loadingInnovators.value = true
    try {
        await getCompetitorsByProduct(moat2ProductStore.alignmentPk, [nodeId], null, true, null)
            .then((response) => {
                const pioneerEntities = getTop5(response.data, 'entryPriorityDt', 'asc').map(
                    (item) => ({
                        ...item,
                        pioneerEntity: item,
                        pioneerEntityName: `${item.aonEntityName} - ${new Date(item.entryPriorityDt).toLocaleDateString('en', { year: 'numeric' })}`,
                        pioneerEntityPk: item.aonEntityPk,
                    })
                )

                const leadingIpHolders = _.chain(response.data)
                    .orderBy('patentCount', 'desc')
                    .take(5)
                    .map((item) => ({
                        ...item,
                        leadingIpHolderEntity: item,
                        leadingIpHolder: `${item.aonEntityName} - ${item.patentCount}`,
                        leadingIpHolderPk: item.aonEntityPk,
                    }))
                    .value()

                const highestMomentumEntities = _.chain(response.data)
                    .filter((item) => item.powerScore >= 50)
                    .orderBy('opportunity', 'desc')
                    .take(5)
                    .map((item) => ({
                        ...item,
                        highestMomentumEntity: item,
                        highestMomentum: `${item.aonEntityName} - ${Math.round(item.opportunity)}`,
                        highestMomentumPk: item.aonEntityPk,
                    }))
                    .value()

                const maxLength = Math.max(
                    pioneerEntities.length,
                    leadingIpHolders.length,
                    highestMomentumEntities.length
                )

                innovatorData.value = Array.from({ length: maxLength }).map((_, index) => ({
                    pioneerEntity: pioneerEntities[index]?.pioneerEntity || null,
                    pioneerEntityName: pioneerEntities[index]?.pioneerEntityName || null,
                    pioneerEntityPk: pioneerEntities[index]?.pioneerEntityPk || null,
                    leadingIpHolderEntity: leadingIpHolders[index]?.leadingIpHolderEntity || null,
                    leadingIpHolder: leadingIpHolders[index]?.leadingIpHolder || null,
                    leadingIpHolderPk: leadingIpHolders[index]?.leadingIpHolderPk || null,
                    highestMomentumEntity:
                        highestMomentumEntities[index]?.highestMomentumEntity || null,
                    highestMomentum: highestMomentumEntities[index]?.highestMomentum || null,
                    highestMomentumPk: highestMomentumEntities[index]?.highestMomentumPk || null,
                }))
            })
            .catch((error) => {
                logger.error(error)
            })
    } catch (err) {
        logger.error(err)
    } finally {
        loadingInnovators.value = false
    }
}
</script>

<style scoped lang="scss">
.innovator-table {
    width: 100%;

    :deep(.target-entity) {
        background: $grey07 !important;
        *:not:deep(.ticker) {
            color: white !important;
        }
    }
}
</style>
