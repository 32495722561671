<template>
    <div class="contender-selection-track">
        <CompetitorSelectionSplit
            v-if="allCompetitors.length > 0 && reportGenerationStore.shouldShowContenderSelection"
            class="flex-grow-1"
            :all-competitor-data="allCompetitors"
            :selected-table-data="selectedCompetitors"
            :max-selected-competitors="props.maxSelectedCompetitors"
            :filter-name-list="[reportGenerationStore.targetCompany]"
            @add-selection="addSelection"
            @delete-selection="updateSelections"
        />
    </div>
</template>

<script setup>
import { ref, onMounted, nextTick, computed, onActivated } from 'vue'
import { useReportGenerationStore } from '@/stores'
import { cloneDeep } from 'lodash-es'

import CompetitorSelectionSplit from '@/components/helpers/CompetitorSelectionSplit.vue'

const reportGenerationStore = useReportGenerationStore()
const emit = defineEmits(['update-tables'])

const props = defineProps({
    maxSelectedCompetitors: {
        type: Number,
        default: 1,
    },
})

onMounted(async () => {
    await nextTick()
})

const allCompetitors = computed(() => {
    return reportGenerationStore.allCompetitorData
})

const selectedCompetitors = computed(() => {
    return reportGenerationStore.reportSelections[3]?.value
})

const addSelection = (competitor) => {
    const currentList = cloneDeep(reportGenerationStore.reportSelections[3].value)
    let newCompetitorList = [...currentList, competitor]
    reportGenerationStore.reportSelections[3].value = newCompetitorList
}

const updateSelections = (competitor) => {
    let newCompetitorList = reportGenerationStore.reportSelections[3].value.filter(
        (item) => item.aonEntityName !== competitor.aonEntityName
    )

    reportGenerationStore.reportSelections[3].value = newCompetitorList
}
</script>

<style lang="scss" scoped>
.contender-selection-track {
    height: 100%;
    width: 100%;

    position: relative;
}
</style>
