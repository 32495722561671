<template>
    <div class="playbook-generation-container">
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" />
        <div class="parent-holder d-flex" v-else>
            <PlaybookStepTracker
                overwrite-class="no-substeps"
                allow-step-navigation
                heading="Create a new Playbook"
                :hide-on-step="stepToHideSideNav"
                :current-step="currentPlaybookStep"
            />
            <KeepAlive>
                <div
                    v-if="
                        playbookGenerationStore.trackStepsFinal.length === 0 ||
                        playbookGenerationStore.trackStepsFinal[0].isCurrentStep
                    "
                    class="w-full pa-5"
                >
                    <h5>Select Playbook Type</h5>
                    <AonCard class="mt-5">
                        <div class="content-holder">
                            <PlaybookTypeSelection />
                        </div>
                    </AonCard>
                </div>
            </KeepAlive>

            <div v-if="showMatchUpPlaybook" class="pa-5 flex-grow-1" style="width: 0">
                <MatchUpPlaybook />
            </div>

            <div v-if="showCounterAssertionPlaybook" class="pa-5 flex-grow-1" style="width: 0">
                <CounterAssertionPlaybook />
            </div>

            <div v-if="showCrossLicensingPlaybook" class="pa-5 flex-grow-1" style="width: 0">
                <CrossLicensingPlaybook />
            </div>
        </div>
        <AonContainer>
            <AonRow style="height: 75px">
                <AonCol class="aon-col-12 pa-0">
                    <PlaybookNav />
                </AonCol>
            </AonRow>
        </AonContainer>

        <!-- Product alignment loading -->
        <AonCoverLoading
            :loading="playbookGenerationStore.productAlignmentLoading"
            :title="`Please hang tight!`"
            :message="loadingMessage"
        >
            <template #footer>
                <progress
                    v-if="playbookGenerationStore.percentComplete !== 0"
                    :value="playbookGenerationStore.percentComplete"
                    max="100"
                    class="mt-4"
                />
            </template>
        </AonCoverLoading>
        <!-- Contender List loading -->
        <AonCoverLoading
            :loading="playbookGenerationStore.contenderListLoading"
            :title="`Loading contender list for ${playbookGenerationStore.targetCompany}`"
            :message="contenderLoadingMessage"
        >
        </AonCoverLoading>
        <!-- Error for product alignment -->
        <AonCoverLoading
            class="error-cover"
            :loading="typeof playbookGenerationStore.error === 'string' ? true : false"
            :show-spinner="false"
            :title="`No Products Found for ${playbookGenerationStore.targetCompany}`"
            :message="playbookGenerationStore.errorMsg"
        >
            <template #header>
                <font-awesome-icon
                    :icon="`fas fa-triangle-exclamation`"
                    style="border-radius: 100%"
                    class="warning--text warning-background pa-4"
                    size="2xl"
                />
            </template>
            <template #footer>
                <ul class="my-4" style="list-style: disc">
                    <li class="my-2">
                        Select another company in the same hierarchy, such as a parent or subsidiary
                        that may be better known.
                    </li>
                    <li class="my-2">Choose a similar company, such as a competitor.</li>
                    <li class="my-2">Build an arena using relevant technology areas.</li>
                </ul>
                <p>
                    If you need further assistance, please contact
                    <a href="mailto:support@moatmetrics.com">support@moatmetrics.com</a>
                </p>
                <div class="button-holder mt-5 w-full d-flex justify-content-center">
                    <AonButton
                        class="mr-5"
                        label="Select a New Target Company"
                        @clicked="resetTargetCompanyStep()"
                    />
                </div>
            </template>
        </AonCoverLoading>
    </div>
</template>

<script setup>
import { computed, ref, onBeforeMount, onMounted } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import PlaybookStepTracker from '@/components/playbooks/shared/PlaybookStepTracker.vue'
import PlaybookNav from '@/components/playbooks/shared/PlaybookNav.vue'
import PlaybookTypeSelection from '@/components/playbooks/shared/PlaybookTypeSelection.vue'

import MatchUpPlaybook from '@/components/playbooks/playbookTypes/matchUp/MatchUpPlaybook.vue'
import CounterAssertionPlaybook from '@/components/playbooks/playbookTypes/counterAssertion/CounterAssertionPlaybook.vue'
import CrossLicensingPlaybook from '@/components/playbooks/playbookTypes/crossLicensing/CrossLicensingPlaybook.vue'

const playbookGenerationStore = usePlaybookGenerationStore()
const router = useRouter()
const { t } = useI18n()

const loading = ref(true)

onBeforeMount(async () => {
    loading.value = false
})

onMounted(() => {})

const loadingMessage = computed(() => {
    if (playbookGenerationStore.needsNewAlignment) {
        return `We are aligning patents to the product and technology areas for ${playbookGenerationStore.targetCompany}. This process will take 5-10 minutes if not cached in our system. Please feel free to check out the other tabs and this process will continue in the background.`
    } else {
        return `Loading Moat2Product for ${playbookGenerationStore.targetCompany}`
    }
})

const contenderLoadingMessage = computed(() => {
    if (playbookGenerationStore.playbookTypeSelected === 'matchUp') {
        return `We'll apply the top 5 contenders for ${playbookGenerationStore.targetCompany}`
    } else {
        return 'Populating the list of competitors'
    }
})

const showMatchUpPlaybook = computed(() => {
    return (
        playbookGenerationStore.playbookTypeSelected === 'matchUp' &&
        playbookGenerationStore.trackStepsFinal.length > 0 &&
        !playbookGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const showCounterAssertionPlaybook = computed(() => {
    return (
        playbookGenerationStore.playbookTypeSelected === 'counterAssertion' &&
        playbookGenerationStore.trackStepsFinal.length > 0 &&
        !playbookGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const showCrossLicensingPlaybook = computed(() => {
    return (
        playbookGenerationStore.playbookTypeSelected === 'crossLicensing' &&
        playbookGenerationStore.trackStepsFinal.length > 0 &&
        !playbookGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const stepToHideSideNav = computed(() => {
    if (
        playbookGenerationStore.playbookTypeSelected === 'matchUp' ||
        playbookGenerationStore.playbookTypeSelected === 'counterAssertion' ||
        playbookGenerationStore.playbookTypeSelected === 'crossLicensing'
    ) {
        return 4
    } else {
        return null
    }
})

const currentPlaybookStep = computed(() => {
    return playbookGenerationStore.getCurrentStep()
        ? playbookGenerationStore.getCurrentStep().stepNum
        : null
})

const resetTargetCompanyStep = () => {
    playbookGenerationStore.error = null
}
</script>

<style lang="scss" scoped>
.playbook-generation-container {
    height: 100%;
    width: 100%;

    .parent-holder {
        height: calc(100% - 78px);
    }

    .content-holder {
        height: 100%;
    }

    .ignore-max-width {
        max-width: none !important;
    }

    .prevent-content-overflow {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
