<template>
    <div class="valuations-tab mb-5">
        <AonSpinner
            v-if="entityStore.loadingEntity"
            class="ma-auto"
            :scale="0.5"
            color="castleMoat"
        />
        <div v-else class="card-holder">
            <AonContainer add-page-padding>
                <AonRow class="mt-5">
                    <AonCol class="aon-col-12 pa-0">
                        <TPECard unique-id="val-tpe" />
                    </AonCol>
                </AonRow>
                <AonRow class="mt-5">
                    <AonCol class="aon-col-12 pa-0">
                        <AcquisitionsCard />
                    </AonCol>
                </AonRow>
                <AonRow class="mt-5 mx-0">
                    <AonCol class="aon-col-12 pa-0">
                        <IPValueCard />
                    </AonCol>
                </AonRow>
                <AonRow v-if="entityStore.entity.stock_ticker" class="mt-5 mx-0">
                    <FundingCard />
                </AonRow>
                <AonRow class="mt-5 mx-0">
                    <AonCol class="aon-col-12 pa-0">
                        <FundingRoundsCard />
                    </AonCol>
                </AonRow>
            </AonContainer>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useEntityStore } from '@/stores'
import { useFlag } from '@unleash/proxy-client-vue'

import { useRoute } from 'vue-router'
import TPECard from './TPECard.vue'
import IPValueCard from './IPValueCard.vue'
import FundingCard from './FundingCard.vue'
import FundingRoundsCard from '@/components/company/tabs/valuations/FundingRoundsCard.vue'
import AcquisitionsCard from '@/components/company/tabs/valuations/AcquisitionsCard.vue'

const entityStore = useEntityStore()
const route = useRoute()
const entityId = route.params.targetPk
const showDashboard = useFlag('ipAlpha.companyDashboard')

onMounted(async () => {})
</script>

<style lang="scss" scoped>
.valuations-tab {
    width: 100%;
    position: relative;
}
</style>
