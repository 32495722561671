<template>
    <div class="insights-card-holder">
        <AonCard
            v-for="insight in insights"
            class="insights-card pa-2 mb-2"
            :key="insight.id"
            :remove-padding="['t', 'r', 'b', 'l']"
        >
            <div class="insight-row d-flex justify-content-between">
                <div class="wrap d-flex">
                    <font-awesome-icon
                        icon="fas fa-lightbulb-on"
                        class="mr-2 warning-hover--text"
                        style="margin-top: 2px"
                    />
                    <p class="insight-type mr-2">
                        <span style="font-weight: 700"
                            >{{ t(`insights.types.${insight.insightType}`) }}:</span
                        >
                        {{ insight.insightText }}
                    </p>
                </div>
                <p
                    style="min-width: 80px"
                    class="learn-more-link link small"
                    @click="learnMoreClick(insight.insightType)"
                >
                    Learn More
                </p>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useEntityStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const entityStore = useEntityStore()
const insights = ref([])
const { t } = useI18n()
const router = useRouter()

const props = defineProps({
    insightTypes: {
        type: Array,
        required: true,
        default: () => [],
    },
})

onMounted(async () => {
    const response = await entityStore.getInsights(
        entityStore.entity.aon_entity_pk,
        props.insightTypes
    )
    insights.value = response
})

const learnMoreClick = (insightType) => {
    if (['key_inventor_left', 'key_inventor_joined'].includes(insightType)) {
        gotoInventorsTab()
    } else if (insightType === 'litigation_risk') {
        gotoRiskTab()
    } else {
        gotoProductTab()
    }
}

const gotoProductTab = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: 'moat2Product' },
    })
}

const gotoInventorsTab = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: 'execution' },
    })
}

const gotoRiskTab = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: 'risk' },
    })
}
</script>

<style lang="scss" scoped>
.insights-card-holder {
    width: 100%;
}
.insights-card {
    &:last-of-type {
        margin-bottom: 0 !important;
    }
    &.a-card {
        background: rgba($gold, 0.4);
    }
}
</style>
