<template>
    <div class="cell-wrapper d-flex align-items-center" style="width: fit-content">
        <div class="link-wrapper h-full d-flex align-items-center" style="width: fit-content">
            <div
                v-if="params.data?.color"
                class="color-chip mr-2"
                :style="{ background: params.data.color }"
            ></div>
            <div
                class="ultimate-parent mr-3"
                v-if="params.data?.ultimateEntityName"
                v-tooltip="{
                    content: `Ultimate Parent - ${params.data.ultimateEntityName}`,
                    placement: 'top',
                    distance: '-5',
                }"
            >
                <font-awesome-icon class="grey01--text" icon="fas fa-buildings" />
            </div>
            <div
                class="company-click-area d-flex align-items-center"
                @click.left.prevent="showPopover = true"
            >
                <a
                    class="popper-hook link overflow-ellipsis"
                    :href="entityRouteLink(getEntityId())"
                    target="_blank"
                >
                    {{ params.value }}
                </a>
            </div>
        </div>
        <div
            v-if="params.data?.stockTicker"
            class="ticker d-inline-flex align-items-center ml-2 pa-1 grey05"
        >
            <p class="small">{{ params.data.stockTicker }}</p>
        </div>
        <CompanyPopover
            :show-popover="showPopover"
            :passed-company-details="params.entityOverwrite ? params.entityOverwrite : params.data"
            @close-popover="showPopover = false"
        />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import CompanyPopover from '../helpers/CompanyPopover.vue'
import { entityRouteLink } from '../../router/utils'

const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
})

const getEntityId = () =>
    props.params.entityOverwrite?.aonEntityPk ||
    props.params.data.aonEntityPk ||
    props.params.data.aon_entity_pk ||
    props.params.data.entityPk ||
    props.params.data.entityId

const showPopover = ref(false)
</script>

<style lang="scss" scoped>
.cell-wrapper {
    .color-chip {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
}
.ticker {
    border-radius: 4px;

    transition: all ease-in-out 0.3s;

    span {
        color: $grey01;
        transition: all ease-in-out 0.3s;
    }
}
</style>
