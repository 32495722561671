import { createAuth0, useAuth0 } from '@auth0/auth0-vue'
import { computed, inject, ref } from 'vue'
import { logger } from '@moatmetrics/vue-logger'

const USER_INJECT_TOKEN = 'user'
const AUTH_CONTEXT_INJECT_TOKEN = 'authContext'

const auth0Client = {
    user: ref(null),
    isAuthenticated: ref(false),
    tosAccepted: ref(true),
    getAccessTokenSilently: () => {},
    logout: async () => {},
    handleRedirectCallback: async () => {},
}

export const useAuth = () => {
    return auth0Client
}

const plugin = {
    install(app, config) {
        const urlParams = new URLSearchParams(window.location.search)

        let authParams = {
            redirect_uri: config.redirectUri,
            audience: config.audience,
        }

        if (urlParams.has('invitation')) {
            authParams.invitation = urlParams.get('invitation')
            authParams.organization = urlParams.get('organization')
        }

        const auth0Plugin = createAuth0(
            {
                domain: config.domain,
                clientId: config.clientId,
                authorizationParams: authParams,
                useCookiesForTransactions: false,
                cacheLocation: 'localstorage',
            },
            {
                skipRedirectCallback: true,
            }
        )

        app.use(auth0Plugin)

        auth0Client.user = computed(() => {
            const user = auth0Plugin.user?.value
            let entity_pk = null
            let entityPkClaimVal = user?.['https://moatmetrics.com/entity_pk']
            const entityPkClaimInt = parseInt(entityPkClaimVal)
            if (isNaN(entityPkClaimInt) && entityPkClaimVal) {
                logger.warn(`Invalid entity_pk claim value ${entityPkClaimVal}`)
            } else if (entityPkClaimInt) {
                entity_pk = entityPkClaimInt
            }
            return {
                ...user,
                id: user?.['https://moatmetrics.com/uuid'],
                entity_pk: entity_pk,
            }
        })

        auth0Client.isAuthenticated = auth0Plugin.isAuthenticated
        auth0Client.getAccessTokenSilently = auth0Plugin.getAccessTokenSilently
        auth0Client.logout = auth0Plugin.logout
        auth0Client.handleRedirectCallback = auth0Plugin.handleRedirectCallback
    },
}

export default plugin
