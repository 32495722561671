<template>
    <div class="risk-tab pa-5 grey04">
        <CaseHighlightsCard class="mb-5" :nodeData="nodeData" />
        <PeerHistoryCard class="mb-5" :nodeData="nodeData" />
        <PatentExpirationCard class="mb-5" :nodeData="nodeData" />
        <CompetitorLitigationCard class="mb-5" :nodeData="nodeData" />
        <DamagesIncurredCard class="mb-5" :nodeData="nodeData" />
        <NPECampaignsCard :nodeData="nodeData" />
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import { useEntityStore } from '@/stores'

import CaseHighlightsCard from './CaseHighlightsCard.vue'
import PeerHistoryCard from './PeerHistoryCard.vue'
import PatentExpirationCard from './PatentExpirationCard.vue'
import CompetitorLitigationCard from './CompetitorLitigationCard.vue'
import DamagesIncurredCard from './DamagesIncurredCard.vue'
import NPECampaignsCard from './NPECampaignsCard.vue'

const entityStore = useEntityStore()

const props = defineProps({
    nodeData: {
        type: Object,
        default: () => {},
    },
    type: {
        type: String,
        default: 'pa',
    },
})
</script>

<style lang="scss" scoped>
.risk-tab {
    width: 100%;
}
</style>
