<template>
    <div class="acquisitions-card">
        <AonCard class="d-flex flex-column h-full" title="Acquisitions" style="height: 400px">
            <AonCoverLoading
                :loading="loadingAcquisitions"
                title="Loading Acquisitions..."
            ></AonCoverLoading>
            <AonCoverLoading
                v-if="noData"
                :loading="noData ? true : false"
                :show-spinner="false"
                title="No Acquisitions Identified"
            >
                <template #header>
                    <div class="icon">
                        <font-awesome-icon
                            icon="fas fa-circle-exclamation"
                            class="grey01--text"
                            size="2xl"
                        />
                    </div>
                </template>
            </AonCoverLoading>
            <template #header-append>
                <p class="">
                    <span style="font-weight: 600">Total Acquisitions:</span>
                    {{ totalAcquisitions.toLocaleString() }}
                </p>
            </template>
            <div class="card-content d-flex flex-column h-full">
                <MoatTable
                    class="table-holder flex-grow-1"
                    style="width: 100%"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :suppress-auto-size="true"
                    :row-data="tableData"
                    @grid-ready="gridInit"
                />
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, inject, computed } from 'vue'
import { useEntityStore } from '@/stores'
import { useI18n } from 'vue-i18n'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator } from '@/components/moatTable/helpers/compare.js'
import { getEntityAcquisitions } from '@/api/entities'

const { t } = useI18n()
const logger = inject('logger')
const filters = inject('filters')
const entityStore = useEntityStore()

const loadingAcquisitions = ref(true)
const noData = ref(false)
const tableData = ref([])
const totalAcquisitions = ref(0)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'acquireeName',
        headerName: 'Company Acquired',
        flex: 2,
    },
    {
        field: 'acquiredOn',
        headerName: 'Announced Date',
        flex: 1,
        sort: 'desc',
        valueFormatter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.acquiredOn)
            }
        },
    },
    {
        field: 'price',
        headerName: 'Price (USD)',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.price)
            }
        },
    },
    {
        field: 'acquisitionDesc',
        headerName: 'Description',
        flex: 2,
    },
    {
        field: 'acquisitionType',
        headerName: 'Acquisition Type',
        flex: 1,
    },
])
onMounted(() => {
    onGetEntityAcquisitions()
})

onBeforeUnmount(() => {})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const onGetEntityAcquisitions = async () => {
    try {
        const { data } = await getEntityAcquisitions(entityStore.entity.aon_entity_pk)

        if (data.results.length === 0) {
            noData.value = true
            loadingAcquisitions.value = false
            return
        }
        totalAcquisitions.value = data.rowCount
        tableData.value = data.results
    } catch (err) {
        logger.error(err)
    } finally {
        loadingAcquisitions.value = false
    }
}
</script>

<style lang="scss" scoped>
.acquisitions-card {
    width: 100%;
    position: relative;
}
</style>
