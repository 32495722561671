<template>
    <div class="technology-drill-in-header pa-5 knights-cloak">
        <div class="tech-path d-flex align-items-center">
            <div
                v-for="step in techPath"
                v-tooltip="{
                    content: step,
                }"
                class="path-step d-flex align-items-center"
            >
                <p v-if="step" class="step-text gold--text overflow-ellipsis">{{ step }}</p>
                <font-awesome-icon
                    v-if="step"
                    icon="fas fa-chevron-right"
                    class="white--text pl-4 pr-2 mr-2"
                    size="sm"
                />
            </div>
        </div>
        <div class="header-content d-flex justify-content-between align-items-center mt-2">
            <div>
                <h4 class="white--text">{{ currentNodeName }}</h4>
                <p class="white--text">{{ currentNodeDescription }}</p>
            </div>
            <AonButton
                v-if="showPatentData"
                class="no-wrap-button"
                label="Export Patent Data"
                text-with-icon
                :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                :loading="exportingPatentData"
                @clicked="exportPatentData()"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { useArenaOutputStore, useMoat2ProductStore } from '@/stores'
import { createAndSubmitReport } from '@/services/reportService'
import { useAuth } from '@/auth/authPlugin.js'
import { useFlag } from '@unleash/proxy-client-vue'
const { user } = useAuth()

const showPatentData = useFlag('ipAlpha.patentData')
const eventBus = inject('eventBus')
const arenaOutputStore = useArenaOutputStore()
const moat2ProductStore = useMoat2ProductStore()

const props = defineProps({
    loading: {
        type: Boolean,
    },
    nodeData: {
        type: Object,
        default: () => {},
    },
})

const techPath = ref([])
const currentNodeName = ref('')
const currentNodeDescription = ref('')
const exportingPatentData = ref(false)

onMounted(() => {
    if (arenaOutputStore.targetCompany.length > 0) {
        techPath.value.push(arenaOutputStore.targetCompany[0].aonEntityName)
    }
    techPath.value = props.nodeData.nodeHierarchy.map((node) => node.displayName)
    if (arenaOutputStore.targetCompany.length > 0) {
        techPath.value.unshift(arenaOutputStore.targetCompany[0].aonEntityName)
    }
    currentNodeName.value = props.nodeData.displayName
    currentNodeDescription.value = props.nodeData.description
})

const exportPatentData = async () => {
    exportingPatentData.value = true
    let reportInput = {
        node_ids: [props.nodeData.nodeId],
        product_alignment_id: moat2ProductStore.alignmentPk,
    }
    await createAndSubmitReport(
        eventBus,
        user,
        'patent_export',
        `${currentNodeName.value} Export`,
        reportInput
    )
    exportingPatentData.value = false
}
</script>

<style lang="scss" scoped>
.technology-drill-in-header {
    width: 100%;
    border-bottom: solid white 1px;

    .path-step {
        &:last-of-type {
            display: none;
        }

        .step-text {
            max-width: 250px;
        }
    }
    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .no-wrap-button {
        white-space: nowrap;
        width: auto;
        align-self: flex-end;
    }
}
</style>
