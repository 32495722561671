<template>
    <div class="head-to-head-report">
        <KeepAlive>
            <SelectTargetCompany v-if="currentStep.stepNum === 2" />
        </KeepAlive>
        <KeepAlive>
            <div
                class="tech-area-holder h-full d-flex flex-column"
                v-if="currentStep.stepNum === 3"
            >
                <h5>Select Technology Areas</h5>
                <AonCard class="mt-4 flex-grow-1">
                    <div class="content-holder h-full d-flex flex-column">
                        <SelectTechAreas
                            v-if="allTechAreas"
                            class="flex-grow-1"
                            :table-data="allTechAreas"
                            :loading="false"
                            :report-step="3"
                            type="report"
                        />
                    </div>
                </AonCard>
            </div>
        </KeepAlive>
        <KeepAlive>
            <ContenderSelectionNoSource
                v-if="currentStep.stepNum === 4"
                :max-selected-competitors="3"
            />
        </KeepAlive>
        <KeepAlive>
            <ReportSummary :key="updater" v-if="currentStep.stepNum === 5" />
        </KeepAlive>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick, computed } from 'vue'
import { useReportGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'

import SelectTargetCompany from '@/components/reports/shared/SelectTargetCompany.vue'
import { default as SelectTechAreas } from '@/components/alignmentOutput/techAreas/OutputTechAreaTable.vue'
import ContenderSelectionNoSource from '@/components/reports/shared/ContenderSelectionNoSource.vue'
import ReportSummary from '@/components/reports/shared/ReportSummary.vue'

const reportGenerationStore = useReportGenerationStore()
const router = useRouter()

const updater = ref(0)
const source = ref(null)

onMounted(async () => {
    await nextTick()
})

watch(
    () => reportGenerationStore.reportSelections,
    (newVal, oldVal) => {
        updater.value++
    },
    { deep: true }
)

watch(
    () => reportGenerationStore.reportSelections[2]?.value,
    (newVal, oldVal) => {
        if (!oldVal && newVal) {
            reportGenerationStore.flagForContenderUpdate()
        }
        if (oldVal && newVal && newVal.length !== oldVal.length) {
            reportGenerationStore.flagForContenderUpdate()
            reportGenerationStore.onTechAreaChange()
        }
    },
    { deep: true }
)

watch(
    () => router,
    (newVal, oldVal) => {
        source.value = newVal.currentRoute.value.query.source
    },
    { flush: 'pre', immediate: true, deep: true }
)

const currentStep = computed(() => {
    return reportGenerationStore.getCurrentStep()
})

const allTechAreas = computed(() => {
    return reportGenerationStore.techAreaNodes
})
</script>

<style lang="scss" scoped>
.head-to-head-report {
    height: 100%;
    width: 100%;
}
</style>
