<template>
    <div class="ip-basics-tab mb-5">
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" color="castleMoat" />
        <div v-else-if="entityStore.patentBreakdown?.length > 1" class="card-holder">
            <WorldViewCard />
            <AonContainer add-page-padding>
                <AonRow class="mt-5">
                    <AonCol class="aon-col-6 pl-0 py-0">
                        <MoatQualityCard />
                        <ClaimBreathCard class="mt-5" />
                    </AonCol>
                    <AonCol class="aon-col-6 pr-0 py-0">
                        <PatentAnalysisCard />
                    </AonCol>
                </AonRow>
                <AonRow class="mt-3">
                    <AonCol class="aon-col-12 px-0">
                        <CORVizCard />
                    </AonCol>
                </AonRow>
            </AonContainer>
        </div>
        <div v-else>
            <AonContainer add-page-padding>
                <AonRow class="mt-5">
                    <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                        <AonCard title="No Patents" :desc="noPatentsFoundMsg"> </AonCard>
                    </AonCol>
                </AonRow>
            </AonContainer>
        </div>
    </div>
</template>

<script setup>
import { watch, ref, onMounted, inject, computed } from 'vue'
import { useEntityStore } from '@/stores'
import { useRoute } from 'vue-router'

import WorldViewCard from './WorldViewCard.vue'
import ClaimBreathCard from './ClaimBreathCard.vue'
import PatentAnalysisCard from './PatentAnalysisCard.vue'
import CORVizCard from './CORVizCard.vue'
import MoatQualityCard from './MoatQualityCard.vue'
import AonCard from '@moatmetrics/armory/src/components/base/AonCard.vue'

const entityStore = useEntityStore()
const route = useRoute()
const entityId = route.params.targetPk

const loading = computed(() => {
    return entityStore.loadingEntity || !entityStore.patentBreakdown
})

const noPatentsFoundMsg = computed(() => {
    return 'No patents identified for ' + entityStore.entity.name + '.'
})
</script>

<style lang="scss" scoped></style>
