<template>
    <div class="company-market-summary">
        <AonDrawer
            class="a-drawer-right"
            width="500px"
            right
            :show-close="false"
            :drawer-open="drawerOpen"
            @input="drawerOpen = false"
        >
            <div class="content-holder">
                <div v-if="loading" class="my-10">
                    <AonSpinner class="mx-auto" :scale="0.5" />
                </div>
                <template v-if="!loading && currentCompany">
                    <div
                        class="header-holder d-flex justify-content-between align-items-center mb-3"
                    >
                        <p class="small">
                            {{
                                currentCompany
                                    ? t('entitySummary.companySummary')
                                    : t('marketSummary.marketSummary')
                            }}
                        </p>
                        <AonButton
                            class="close"
                            type="icon"
                            :style="{ color: '#000' }"
                            :icon-options="{ iconStyle: 'fas', iconName: 'fa-xmark' }"
                            @clicked="drawerOpen = false"
                        />
                    </div>

                    <!-- Company Summary -->
                    <div class="summary-holder-entity" v-if="currentCompany">
                        <EntityTitle class="mb-5" :entity="currentCompany" />
                        <Description
                            :title="currentCompany.name"
                            :description="currentCompany.description"
                            :dark="false"
                        />
                        <div
                            class="stats-holder-entity d-flex justify-content-between flex-wrap my-5"
                        >
                            <div v-if="currentCompany.entity_type" class="w-half mb-2">
                                <font-awesome-icon icon="fas fa-flag" class="fa-fw mr-2" />
                                <span class="caption grey01--text">
                                    {{ currentCompany.entity_type }}
                                </span>
                            </div>
                            <div v-if="currentCompany.address" class="w-half mb-2 d-flex pl-1">
                                <font-awesome-icon
                                    icon="fas fa-map-marker-alt"
                                    class="fa-fw mr-2 d-inline"
                                />
                                <span class="caption grey01--text">{{
                                    currentCompany.address.replace(/,/g, ', ')
                                }}</span>
                            </div>
                            <a
                                v-if="currentCompany.homepage_url"
                                :href="currentCompany.homepage_url"
                                target="_blank"
                                class="grey01--text w-half mb-2"
                            >
                                <font-awesome-icon icon="fas fa-external-link" class="fa-fw mr-2" />
                                <span class="caption">{{ currentCompany.homepage_url }}</span>
                            </a>
                            <div v-if="totalRevenue" class="w-half mb-2">
                                <font-awesome-icon icon="fas fa-sack-dollar" class="fa-fw mr-2" />
                                <span class="caption grey01--text">
                                    {{ totalRevenue }} {{ revenueYear }}
                                </span>
                            </div>
                        </div>
                        <div class="button-holder d-flex justify-content-between mb-5">
                            <AonButton
                                class="w-half mr-5"
                                type="prominent"
                                :route-params="{
                                    name: 'Research Company',
                                    params: { targetPk: currentCompany.aon_entity_pk },
                                }"
                                :label="$t('marketSummary.explore')"
                                @clicked="exploreCompany(currentCompany)"
                            />
                            <AddToWatchlistButton
                                class="w-half"
                                id-type="Entity"
                                :id-values="[currentCompany.aon_entity_pk]"
                                style="min-width: 0"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </AonDrawer>
    </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useEntityStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { AonDrawer } from '@moatmetrics/armory/src/components'
import Description from '@/components/Description'
import AddToWatchlistButton from '../Watchlists/WatchlistActions/AddToWatchlistButton'
import EntityTitle from '@/components/company/EntityTitle'

import NumberAbbreviate from 'number-abbreviate'
import moment from 'moment'

const entityStore = useEntityStore()
const router = useRouter()

const { t } = useI18n()
const numberAbbreviate = new NumberAbbreviate(['K', 'M', 'B', 'T'])

// component logic
const drawerOpen = ref(false)
const loading = ref(false)

watch(
    () => drawerOpen.value,
    async (newVal, oldVal) => {
        if (!newVal) {
            entityStore.summaryEntity = null
        }
    }
)

watch(
    () => entityStore.summaryEntity,
    async (newVal, oldVal) => {
        if (!newVal) {
            drawerOpen.value = false
        }
        if (newVal && newVal !== oldVal) {
            drawerOpen.value = true
        }
    }
)

const currentCompany = computed(() => {
    return entityStore.summaryEntity
})

const totalRevenue = computed(() => {
    return !currentCompany.value || !currentCompany.value.total_revenue
        ? null
        : `$${numberAbbreviate.abbreviate(currentCompany.value.total_revenue)}`
})

const revenueYear = computed(() => {
    if (!currentCompany.value) {
        return null
    }
    return `(${moment.utc(currentCompany.value.revenue_end_date).format('YYYY')})`
})

const exploreCompany = (company) => {
    drawerOpen.value = false
    if (company) {
        router.push({
            name: 'Research Company',
            params: { targetPk: company.aon_entity_pk },
            query: { tabName: 'profile' },
        })
    }
}
</script>

<style lang="scss" scoped>
.company-market-summary {
    z-index: 3;

    :deep(.table-aips-latest) {
        border: none !important;

        .overflow-x-scroll,
        .aips-table,
        .aips-table-wrap {
            transform: none !important;
            border: none !important;
            padding: 0;
        }

        .aips-table {
            table-layout: auto !important;
        }

        thead tr {
            border: none !important;

            th {
                border: none !important;
                padding: 8px !important;
            }
        }

        tbody tr td {
            padding: 8px !important;
            border: none !important;
        }

        .table-header {
            display: none;
        }
    }

    :deep(.a-drawer) {
        .a-drawer-mask {
            height: calc(100% + 111px);
        }
    }
}
</style>
