<template>
    <div class="match-up-report">
        <KeepAlive>
            <TargetCompanySelection v-if="currentStep.stepNum === 2" />
        </KeepAlive>
        <KeepAlive>
            <CompetitorSelection v-if="currentStep.stepNum === 3" :stepNum="3" />
        </KeepAlive>
        <KeepAlive>
            <MatchUpOutput v-if="currentStep.stepNum === 4" />
        </KeepAlive>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick, computed } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'

import TargetCompanySelection from '@/components/playbooks/shared/TargetCompanySelection.vue'
import CompetitorSelection from '../../shared/CompetitorSelection.vue'
import MatchUpOutput from './MatchUpOutput.vue'

const playbookGenerationStore = usePlaybookGenerationStore()
const router = useRouter()

const updater = ref(0)
const source = ref(null)

onMounted(async () => {
    await nextTick()
})

watch(
    () => playbookGenerationStore.reportSelections,
    (newVal, oldVal) => {
        updater.value++
    },
    { deep: true }
)

watch(
    () => router,
    (newVal, oldVal) => {
        source.value = newVal.currentRoute.value.query.source
    },
    { flush: 'pre', immediate: true, deep: true }
)

const currentStep = computed(() => {
    return playbookGenerationStore.getCurrentStep()
})
</script>

<style lang="scss" scoped>
.match-up-report {
    height: 100%;
    width: 100%;
}
</style>
