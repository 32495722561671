<template>
    <div class="select-contender-source">
        <h5>Select Contender Source</h5>
        <AonCard class="mt-4" title="How would you like to populate the content of this report?">
            <div class="content-holder">
                <AonCard
                    v-for="item in contenderOptions"
                    class="contender-option"
                    :class="{
                        selected: reportGenerationStore.contenderSource === item.value,
                    }"
                    :key="item.label + '_key'"
                    @click="setContenderType(item.value)"
                >
                    <div class="content-holder">
                        <div class="icon-holder">
                            <div :id="item.iconID" class="contender-option-icon"></div>
                            <img
                                src="@/assets/svgs/report/checkmark-circle.svg"
                                class="contender-option-icon checkmark"
                            />
                        </div>
                        <p class="label bold mt-4">{{ item.label }}</p>
                        <p class="mt-1">{{ item.description }}</p>
                    </div>
                </AonCard>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick, computed } from 'vue'
import { useReportGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'

import moat2ProductTypeUrl from '@/assets/svgs/report/moat2product_type.svg'
import moat2ProductChipUrl from '@/assets/svgs/report/moat2product_chip.svg'
import moat2ProductDocPlusDocUrl from '@/assets/svgs/report/moat2product_doc_plus_doc.svg'

const reportGenerationStore = useReportGenerationStore()

const contenderOptions = ref([
    {
        label: `Dynamically generate contenders for ${reportGenerationStore.reportSelections[1].selectionLabel}`,
        description:
            'Top contenders will be automatically identified and mapped to the selected product and technology areas.',
        iconID: 'chip-icon',
        value: 'auto',
    },
    {
        label: `Manually select contenders`,
        description: `Handpick the competitors you want to benchmark against ${reportGenerationStore.reportSelections[1].selectionLabel}.`,
        iconID: 'doc-plus-icon',
        value: 'manual',
    },
])

onMounted(async () => {
    await nextTick()
    document.getElementById('chip-icon').style.backgroundImage = `url("${moat2ProductChipUrl}")`
    document.getElementById('doc-plus-icon').style.backgroundImage =
        `url("${moat2ProductDocPlusDocUrl}")`
})

const setContenderType = (value) => {
    if (value === 'auto') {
        reportGenerationStore.setShouldFillContenders(true)
    } else {
        reportGenerationStore.setShouldFillContenders(false)
    }
    reportGenerationStore.contenderSource = value
}
</script>

<style lang="scss" scoped>
.select-contender-source {
    height: 100%;
    width: 100%;

    .contender-option {
        cursor: pointer;
        outline: solid transparent 3px;

        transition: all 0.3s ease-in-out;

        &:first-of-type {
            margin-bottom: 20px;
        }

        &:hover {
            background: $grey05;
        }

        &.selected {
            border-color: $success;
            outline-color: $success;

            .label {
                color: $castleMoat;
            }

            .icon-holder {
                .contender-option-icon {
                    opacity: 0;
                }

                .checkmark {
                    opacity: 1;
                }
            }
        }
    }

    .icon-holder {
        position: relative;

        .contender-option-icon {
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;

            transition: all 0.3s ease-in-out;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;
        }
    }

    .label {
        transition: all 0.3s ease-in-out;
    }
}
</style>
