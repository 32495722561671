<template>
    <AonModal class="technology-drill-in" col-width="8" @close="closeModal()">
        <div class="modal-content grey04">
            <AonCoverLoading
                :loading="loadingNodeDetails"
                title="Populating Node Details..."
                message="This might take a few minutes depending on how many child nodes are present."
            >
                <template #footer>
                    <progress
                        v-if="percentComplete !== 0"
                        :value="percentComplete"
                        max="100"
                        class="mt-4"
                    />
                </template>
            </AonCoverLoading>
            <div v-if="!loadingNodeDetails" class="node-details">
                <TechnologyDrillInHeader :nodeData="nodeData" />
                <AonTabs
                    class="pt-4 pl-4 pr-4 knights-cloak-80"
                    :current-tab="currentTab"
                    :tabs="tabData"
                    @tab-change="tabChange"
                />
                <KeepAlive>
                    <OverviewTab v-if="currentTab === 1" :nodeData="nodeData" :type="props.type" />
                </KeepAlive>
                <KeepAlive>
                    <InvestmentActivityTab
                        v-if="currentTab === 2"
                        :nodeData="nodeData"
                        :type="props.type"
                    />
                </KeepAlive>
                <KeepAlive>
                    <RiskTab v-if="currentTab === 3" :nodeData="nodeData" :type="props.type" />
                </KeepAlive>
                <KeepAlive>
                    <InnovatorTab v-if="currentTab === 4" :nodeData="nodeData" :type="props.type" />
                </KeepAlive>
            </div>
        </div>
    </AonModal>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, inject, nextTick } from 'vue'
import { useEntityStore, useArenaOutputStore } from '@/stores'
import { getNodeDetails, pollPostNodeProcessing } from '@/api/ipNode.js'
import { useFlag } from '@unleash/proxy-client-vue'

import TechnologyDrillInHeader from './TechnologyDrillInHeader.vue'
import OverviewTab from './overview/OverviewTab.vue'
import InvestmentActivityTab from './investmentActivity/InvestmentActivityTab.vue'
import RiskTab from './risk/RiskTab.vue'
import InnovatorTab from '@/components/company/drillIn/innovator/InnovatorTab.vue'

const entityStore = useEntityStore()
const arenaOutputStore = useArenaOutputStore()
const showInnovatorsTab = useFlag('ipAlpha.innovators')

const logger = inject('logger')
const rumAgent = inject('rumAgent')

const props = defineProps({
    type: {
        type: String,
        default: 'pa',
    },
})

const loadingNodeDetails = ref(true)
const percentComplete = ref(0)
const interval = ref(true)
const nodeData = ref(null)
const currentTab = ref(1)
const tabData = ref([
    {
        text: 'Innovation Focus',
        id: 1,
    },
    {
        text: 'Investment Activity',
        id: 2,
    },
    {
        text: 'Risk',
        id: 3,
    },
])

const priorViewName = ref(null)

const store = ref(null)

onMounted(async () => {
    showInnovatorsTab.value && tabData.value.push({ text: 'Innovators', id: 4 })

    store.value = entityStore.drillInTargetId ? entityStore : arenaOutputStore
    priorViewName.value = rumAgent.getView()

    try {
        postNodeProcess()
        interval.data = setInterval(async () => {
            postNodeProcess()
        }, 5000)
    } catch (err) {
        logger.error(err)
    }

    tabChange(tabData.value[0])
})

onBeforeUnmount(() => {
    clearInterval(interval.data)
})

const postNodeProcess = async () => {
    loadingNodeDetails.value = true

    await pollPostNodeProcessing(
        store.value.drillInTargetId,
        async (data) => {
            // success
            clearInterval(interval.data)
            onGetNodeDetails(store.value.drillInTargetId)
        },
        (err) => {
            // failure
            percentComplete.value = 0
            logger.error(err)
            clearInterval(interval)
        },
        (percent) => {
            // progress
            percentComplete.value = percent
        }
    )
}

const onGetNodeDetails = async () => {
    try {
        let { data } = await getNodeDetails(store.value.drillInTargetId)
        nodeData.value = data
    } catch (error) {
        logger.error(error)
    } finally {
        loadingNodeDetails.value = false
    }
}

const closeModal = () => {
    store.value.showDrillIn = false
    rumAgent.setView(priorViewName.value)
}

const tabChange = (val) => {
    currentTab.value = val.id
    const tabName = tabData.value.find((tab) => tab.id === val.id)?.text
    rumAgent.setView(`Tech Drill In - ${tabName} Tab`)
}
</script>

<style lang="scss" scoped>
.technology-drill-in {
    width: 100%;
    .modal-content {
        min-height: 500px;
    }
    :deep(.aon-container) {
        .a-modal-close {
            svg {
                color: white !important;
            }
        }
        .container {
            background: $grey04 !important;
            padding: 0 !important;
            max-height: 80vh !important;
            max-height: none;
            overflow-y: auto;
        }
    }
    .table-holder {
        position: relative;
    }
}
</style>
