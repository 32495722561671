import axios from 'axios'

const baseUrl = 'api/ipnode'
const baseUrlV2 = 'api/V2.0/ipnode'

const abortControllers = []

export function pollPostNodeProcessing(nodeId, success, failure, progress = () => {}) {
    return new Promise((resolve, reject) => {
        const controller = new AbortController()
        const signal = controller.signal
        abortControllers.push(controller)

        return axios
            .post(`${baseUrl}/${nodeId}/process`, null, { signal })
            .then((response) => {
                const isProcessing = response.data.some((item) => item.dataStatus === 'processing')
                if (isProcessing) {
                    // Continue polling
                    const numProcessing = response.data.filter(
                        (item) => item.dataStatus === 'processing'
                    ).length
                    const totalNodes = response.data.length
                    return progress(((totalNodes - numProcessing) / totalNodes) * 100)
                }
                success(response)
                resolve(response)
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    reject(error)
                }
                failure(error)
            })
    })
}

export function getNodeDetails(nodeId) {
    return axios.get(`${baseUrl}/${nodeId}/details`)
}

export function getInvestmentDetails(nodeId) {
    return axios.get(`${baseUrl}/${nodeId}/investment_details`)
}

export function getNodeTopPrivateInvestors(nodeId, params) {
    return axios.get(`${baseUrl}/${nodeId}/top_investors`, { params })
}

export function getNodeTopAcquirers(nodeId) {
    return axios.get(`${baseUrl}/${nodeId}/top_acquirers`)
}

export function getNodeTopIPOs(nodeId) {
    return axios.get(`${baseUrl}/${nodeId}/top_ipos`)
}

export function getNodeCompetitors(nodeId) {
    return axios.get(`${baseUrl}/${nodeId}/competitors`)
}

export function getCompanyFocusAreas(entityPk, nodeId) {
    const params = {
        entity_pk: entityPk,
    }
    return axios.get(`${baseUrlV2}/${nodeId}/patent_focus`, { params })
}

export function getInvestmentsByYear(nodeId) {
    return axios.get(`${baseUrl}/${nodeId}/investment_types_by_year`)
}

export function getPatentExpiration(nodeId, params) {
    return axios.get(`${baseUrl}/${nodeId}/patent_expiration_forecast`, { params })
}

export function getEntityNodePatentCount(nodeId, entityPk) {
    return axios.get(`${baseUrl}/${nodeId}/entity/${entityPk}/patent_count`)
}

//Litigation
export function getLitigationSummary(nodeId, params) {
    return axios.get(`${baseUrl}/${nodeId}/litigation/summary`, { params })
}

export function getCompetitorLitigation(nodeId, params) {
    return axios.get(`${baseUrl}/${nodeId}/litigation/entities_summary_years`, { params })
}

export function getLitigationCases(nodeId, params) {
    return axios.get(`${baseUrl}/${nodeId}/litigation/case`, {
        params,
        paramsSerializer: { indexes: null },
    })
}

export function getNPECampaigns(nodeId, params) {
    return axios.get(`${baseUrl}/${nodeId}/litigation/npe_campaigns`, {
        params,
        paramsSerializer: { indexes: null },
    })
}

export function getEntitiesLitigationSummary(nodeId) {
    const params = {
        SC: [],
        SD: [],
        SP: null,
        FD: [null],
        FC: ['cases_count'],
        FT: ['gt'],
        FV: [0],
        page_size: 10000,
    }
    return axios.get(`${baseUrl}/${nodeId}/litigation/entities_summary`, {
        params,
        paramsSerializer: { indexes: null },
    })
}

// Consider moving this endpoint to a new file if we get any more npe campaign endpoints
export function getCasesForNPE(npeId, params) {
    return axios.get(`api/NpeCampaign/${npeId}/case`, {
        params,
        paramsSerializer: { indexes: null },
    })
}
