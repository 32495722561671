<template>
    <AonCard
        class="latest-revenue-card d-flex flex-column h-full"
        title="Latest Revenue by Segment as Reported"
    >
        <div class="card-content h-full flex-grow-1">
            <AonCoverLoading
                :loading="loadingBreakdown"
                title="Loading History..."
            ></AonCoverLoading>
            <MoatTable
                class="table-holder h-full flex-grow-1"
                :class="themeClass"
                :column-defs="colDefs"
                :row-data="tableData"
                @grid-ready="gridInit"
            />
            <!-- :auto-group-column-def="autoGroupColumnDef" -->
            <AonCoverLoading
                v-if="noData"
                :loading="noData ? true : false"
                :show-spinner="false"
                title="No Reported Revenue"
            >
                <template #header>
                    <div class="icon">
                        <font-awesome-icon
                            icon="fas fa-circle-exclamation"
                            class="grey01--text"
                            size="2xl"
                        />
                    </div>
                </template>
            </AonCoverLoading>
        </div>
    </AonCard>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import { useEntityStore } from '@/stores'
import { getBusinessSegmentRev } from '@/api/entities.js'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator } from '@/components/moatTable/helpers/compare.js'

const entityStore = useEntityStore()

const filters = inject('filters')
const logger = inject('logger')

const loadingBreakdown = ref(false)
const tableData = ref([])
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'businessSegmentName',
        headerName: 'Business Segment Name',
        rowGroup: true,
        hide: true,
        flex: 1,
    },
    {
        field: 'totalRevenue',
        headerName: 'Total Revenue',
        valueFormatter: (params) => (params.value ? filters.abbreviate(params.value) : ''),
        flex: 1,
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.startDate)
            }
        },
        comparator: dateComparator,
        flex: 1,
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.endDate)
            }
        },
        comparator: dateComparator,
        flex: 1,
    },
])

const noData = ref(false)

const autoGroupColumnDef = ref({
    field: 'businessSegmentName',
    headerName: 'Business Segment Name',
    minWidth: 420,
    // headerValueGetter: (params) => 'Your New Column Name Here',
    cellRendererParams: {
        suppressCount: true,
    },
})

onMounted(() => {
    fetchRevBreakdown()
})

const gridInit = (params) => {
    gridApi.value = params.api
}

const fetchRevBreakdown = async () => {
    loadingBreakdown.value = true

    try {
        const { data } = await getBusinessSegmentRev(entityStore.entity.aon_entity_pk)
        let flatData = []
        data.map((item) => {
            flatData.push(item.businessSegmentRevenues)
        })
        tableData.value = flatData.flat()
    } catch (error) {
        logger.error(error)
    } finally {
        loadingBreakdown.value = false
    }
}
</script>

<style lang="scss" scoped>
.latest-revenue-card {
    width: 100%;
    position: relative;
    height: 400px;

    .mini-card {
        border-radius: calc($border-radius * 2);
        border: solid 1px $grey04;
    }

    :deep(.a-cover-loading) {
        .holder {
            padding-top: 10px !important;
        }
    }
}
</style>
