<template>
    <div class="target-company-selection-table d-flex flex-column">
        <EntitySelectionTable
            :table-heading="props.heading"
            :table-sub-heading="props.subHeading"
            init-empty-table
            :init-empty-table-message="noDataMessage"
            persist-selections
            @selected-entities="onSelectEntities"
        />
    </div>
</template>

<script setup>
import { ref, inject, onMounted, nextTick, computed, onActivated } from 'vue'
import { usePlaybookGenerationStore } from '@/stores'

import EntitySelectionTable from '@/components/helpers/EntitySelectionTable'
const playbookGenerationStore = usePlaybookGenerationStore()

const props = defineProps({
    heading: {
        type: String,
        default: 'Select Target Product Arena',
    },
    subHeading: {
        type: String,
        default:
            'Search for and select a company to apply its product and technology areas for comparison.',
    },
    noDataMessage: {
        type: String,
        default:
            "To begin, please use the input above to search for a target company's product arena.",
    },
})

onMounted(async () => {
    await nextTick()
})

onActivated(async () => {
    await nextTick()
})

const onSelectEntities = (comp) => {
    playbookGenerationStore.setPlaybookCompanySelection(comp[0])
}
</script>

<style lang="scss" scoped>
.target-company-selection-table {
    height: 100%;
    width: 100%;

    position: relative;

    :deep(.search-companies-table) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}
</style>
